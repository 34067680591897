import axios from "axios";
// const API_URL = 'http://localhost:5000/api'


const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });

const getToken = ()=>{
    const token = localStorage.getItem('bingo_access_token');
    const config = {
        headers:{
            Authorization: `Bearer ${token}`
        }
    };
    return config;
}


const create_winning_transaction = async (game_data) => {
    //validate game_data client side
    //return error if validation fails;throw error
    //send data

    try {
        var res = await axiosInstance.post('/transaction', game_data, getToken());
        return res.data;
    } catch (error) {
        try {
        game_data.reason = error?.message;
        game_data.is_failed_save =true;
        
        
        // If it's not an authentication (401) error, save the transaction locally
        if (!error.response || (error.response && error.response.status !== 401)) {
            
            save_to_local_storage(game_data);
        }
        checkStatusAndRefreshPage(error);
        ;
        throw error;
        } catch (error) {
            console.log(error);
        }
        
    }
}

const save_to_local_storage = (game_data) => {
    try {
      // Retrieve existing failed transactions from local storage
      const failedTransactions = JSON.parse(localStorage.getItem('_hag_ftrxs_')) || [];
  
      // Ensure it's an array (in case of data corruption)
      if (!Array.isArray(failedTransactions)) {
        console.error('Failed transactions is not an array, resetting to empty.');
        failedTransactions = [];
      }
  
      // Add new failed transaction with a timestamp
      failedTransactions.push({ ...game_data, timestamp: new Date().toISOString() });
  
      // Save the updated array back to local storage
      localStorage.setItem('_hag_ftrxs_', JSON.stringify(failedTransactions));
      
      console.log('Transaction saved to local storage:', game_data);
    } catch (error) {
      console.error('Error saving transaction to local storage:', error);
    }
  };

const void_transaction = async(trx_id)=>{
    try {
        console.log(trx_id);
        const trx_data = {trxId:trx_id};
        var res = await axiosInstance.post('/transaction/void',trx_data,getToken());
        return res.data;
    } catch (error) {
        checkStatusAndRefreshPage(error);
        console.log(error);
        throw error;
    }
}

const update_transaction = async(trx_data)=>{
    try {
        console.log(trx_data);
        var res = await axiosInstance.put('/transaction',trx_data,getToken());
        return res.data;
    } catch (error) {
        checkStatusAndRefreshPage(error);
        console.log(error);
        throw error;
    }
}

const checkStatusAndRefreshPage = async(error)=>{
    try {
        if(error.response.status === 401){
            window.location.reload();
            localStorage.removeItem('bingo_user_info');
            localStorage.removeItem('bingo_access_token');
        }
    } catch (error) {
        
    }
}

const gamePlayService = { create_winning_transaction,void_transaction,update_transaction };
export default gamePlayService;