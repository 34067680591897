import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import CardSelector from '../CardSelector';
import { useDispatch } from 'react-redux';
import { get_all_boards, resetSelectedBoardIds,addOrRemoveSelectedBoard } from 'src/redux/slices/boardSlice';
import {getPlayPercentage,getBetAmount} from 'src/redux/slices/userSlice';
import { useSelector } from 'react-redux';
import Sldr from '@mui/material/Slider';
import { palette, Stack } from '@mui/system';
import { TextField, InputAdornment, IconButton } from '@mui/material';
import Iconify from '../../iconify';
import { Grid,Container,Divider } from '@mui/material';
import { reset_gamePlay_data, set_bet_amount, set_fixed_amount, set_game_type, set_total_players } from 'src/redux/slices/gamePlaySlice';


import Paper from '@mui/material/Paper';
import { styled } from '@mui/system';
import { useTheme } from '@mui/material/styles'; // Hook to access theme

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  elevation: 30,
  height:'100vh',
  background:theme.palette.primary.main
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="top" ref={ref} {...props} />;
});


function CartelaSelect({enable_cartela_selection,show_number_of_players,show_game_type}) {

    const theme = useTheme();
    const [open, setOpen] = React.useState(false);

    const logged_in_user = useSelector((state) => state.auth.user);
    const bet_amount = useSelector((state) => state.transactionState.bet_amount);

    const game_type = useSelector((state) => state.transactionState.game_type);

    const game_typex = useSelector((state) => state.auth.play_percentage);//play percentage set from admin
    const bet_amountx = useSelector((state) => state.auth.bet_amount);//bet amount set from admin

    const fixed_winning = useSelector((state) => state.transactionState.fixed_winning);
    const selectedBoards = useSelector((state) => state.boards.selected_board_ids);
    const all_boards = useSelector((state) => state.boards.available_playing_boards);

    const [board_number, set_board_number] = useState(null);
    

    const dispatch = useDispatch();

    useEffect(()=>{
      
      async function getPlayPercent(){
        await dispatch(getPlayPercentage());
        await dispatch(getBetAmount());
      } 
      getPlayPercent();
    },[game_typex,bet_amountx]);

    useEffect(()=>{
      dispatch(set_bet_amount(bet_amountx));
    },[bet_amountx]);


    const handleClickOpen = async () => {
        await dispatch(get_all_boards(logged_in_user.branch));
        await dispatch(getPlayPercentage());
        setOpen(true);
    };

    const handleGameTypeValue = (typeValue) => {
      if (typeValue > 12) {
        // Check if the value is a multiple of 2
        if (typeValue % 2 === 0) {
          return (typeValue * 2) / 5;
        } else {
          // Round to the next multiple of 2 and then perform the operation
          const nextMultipleOfTwo = Math.ceil(typeValue / 2) * 2;
          return (nextMultipleOfTwo * 2) / 5;
        }
      }
      return typeValue; // return the original value if not greater than 12
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleClear = () => {
        setOpen(false);
        dispatch(reset_gamePlay_data());
        dispatch(resetSelectedBoardIds());
    };

    
    const handleSave = async () => {
        setOpen(false);
        //dispatch(set_total_players(selectedBoards.length));
        if(show_game_type){
          await dispatch(getPlayPercentage());
        }
        dispatch(set_total_players({_total_players:selectedBoards.length,_saved_game_type:show_game_type,_play_percent:game_typex}));
    }

    const _handleCartelaKeyDown = async (e) => {
      if (e.key === 'Enter' && board_number > 0) {
        console.log(all_boards);
        if (all_boards?.some((t) => t.board_id == board_number)) {
          if (selectedBoards?.includes(board_number)) {
            var conf = confirm('Board number already selected. Do you want to deselect it?');
            if (conf) {
              dispatch(addOrRemoveSelectedBoard(board_number));
              set_board_number('');
            }
          } else {
            dispatch(addOrRemoveSelectedBoard(board_number));
            set_board_number('');
          }
        } else {
          alert(`cartela number ${board_number} not available!`);
        }
      }
    };

    return (
      <>
        <button
          style={{ color : theme.palette.primary.text,backgroundColor: theme.palette.primary.main,borderRadius:'10px' }}
          disabled={!enable_cartela_selection}
          onClick={handleClickOpen}
          onMouseOver={(e) => {
            e.target.style.backgroundColor = theme.palette.primary.light;
            e.target.style.color = theme.palette.primary.text;
          }}
          onMouseOut={(e) => {
            e.target.style.backgroundColor = theme.palette.primary.main;
          }}
        >
          ካርቴላ ምረጥ
        </button>

        <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
          <AppBar
            sx={{
              top: 'auto',
              bottom: 0,
              position: 'fixed',
              backgroundColor: 'white',
              color: theme.palette.primary.main,
            }}
          >
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                <CloseIcon />
              </IconButton>

              <Button style={{ marginRight: '2px', width: '100%' }} autoFocus color="inherit" onClick={handleSave}>
                Save
              </Button>
              <Button style={{ width: '100%' }} autoFocus color="inherit" onClick={handleClear}>
                Clear
              </Button>
            </Toolbar>
          </AppBar>
          <Container>
            <Grid container spacing={2}>
              <Grid item xs={12} md={5}>
                <CardSelector selected={false}/>
              </Grid>
              <Grid item xs={12} md={1}>
                <Divider orientation="vertical" style={{ background: theme.palette.primary.main }} flexItem />
              </Grid>

              <Grid item xs={12} md={6}>
                <StyledPaper>
                  <Stack
                    direction={'row'}
                    sx={{
                      padding: '12px',
                      gap: '24px',
                      width: '100%',
                      alignItems: 'center',
                      margin: 'auto',
                      borderRadius: '10px',
                    }}
                  >
                    <TextField
                      sx={{
                        input: {
                          textAlign: 'center',
                          color:theme.palette.primary.text,
                          fontWeight: 'bolder',
                          fontSize: '24px',
                          boxSizing: 'content-box',
                          '&:focus': { color: theme.palette.primary.text },
                        },
                        label: { color: theme.palette.primary.text, '&:focus': { color: theme.palette.primary.text } },
                        '& .MuiInputLabel-root.Mui-focused': {
                          color: theme.palette.primary.text,
                        },
                        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                          borderColor: theme.palette.primary.text,
                        },
                      }}
                      style={{ display: logged_in_user.is_fixed_winning ? 'none' : 'block' }}
                      error=""
                      name="gametype"
                      label="ጨዋታ አይነት"
                      type="number"
                      min={1}
                      max={12}
                      value={
                        // game_typex && parseInt(game_typex) > 0
                        //   ? game_type < game_typex
                        //     ? game_typex
                        //     : game_type
                        //   : game_type
                        
                        // show_game_type
                        // ? game_type
                        // : game_typex && parseInt(game_typex) > 0
                        // ? game_type < game_typex
                        //   ? game_typex
                        //   : game_type
                        // : game_type

                        
                          show_game_type
                            ? handleGameTypeValue(game_type)
                            : game_typex && parseInt(game_typex) > 0
                            ? game_type < game_typex
                              ? handleGameTypeValue(game_typex)
                              : handleGameTypeValue(game_type)
                            : handleGameTypeValue(game_type)
                        
                      } 
                      InputProps={{
                        inputMode: 'numeric',
                        pattern: '[0-9]*',
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              sx={{ color: theme.palette.primary.text, padding: '0px' }}
                              // onClick={() =>
                              //   game_typex && parseInt(game_typex) > 0
                              //     ? game_type <= game_typex
                              //       ? dispatch(set_game_type(game_typeX))
                              //       : dispatch(set_game_type(game_type - 1))
                              //     : dispatch(set_game_type(game_type - 1))
                              // }
                              onClick={() => {
                                if (show_game_type) {
                                  dispatch(set_game_type(game_type - 1));
                                } else {
                                  if (game_typex && parseInt(game_typex) > 0) {
                                    console.log(game_typex)
                                    if (game_type <= game_typex) {
                                      dispatch(set_game_type(game_typeX));
                                    } else {
                                      dispatch(set_game_type(game_type - 1));
                                    }
                                  } else {
                                    dispatch(set_game_type(game_type - 1));
                                  }
                                }
                              }}
                              edge="end"
                            >
                              <Iconify style={{ width: '32px', height: '32px' }} icon="ci:minus-square" />
                            </IconButton>
                          </InputAdornment>
                        ),
                        startAdornment: (
                          <InputAdornment position="start" sx={{ padding: '0px' }}>
                            <IconButton
                              sx={{ color: theme.palette.primary.text, padding: '0px' }}
                              onClick={() => dispatch(set_game_type(game_type + 1))}
                              edge="end"
                            >
                              <Iconify style={{ width: '32px', height: '32px' }} icon="material-symbols:add-box" />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />

                    <TextField
                      sx={{
                        input: {
                          textAlign: 'center',
                          fontWeight: 'bolder',
                          color:theme.palette.primary.text,
                          fontSize: '24px',
                          boxSizing: 'content-box',
                          '&:focus': { color: theme.palette.primary.text },
                        },
                        label: { color: theme.palette.primary.text, '&:focus': { color: theme.palette.primary.text } },
                        '& .MuiInputLabel-root.Mui-focused': {
                          color: theme.palette.primary.text,
                        },
                        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                          borderColor: theme.palette.primary.text,
                        },
                      }}
                      error=""
                      name="bet"
                      label="መደብ"
                      type="number"
                      onChange={(e) => dispatch(set_bet_amount(e.target.value))}
                      value={
                        bet_amountx && parseInt(bet_amountx) > 0
                          ? bet_amount < bet_amountx
                            ? bet_amountx
                            : bet_amount
                          : bet_amount
                      }
                      InputProps={{
                        inputMode: 'numeric',
                        pattern: '[0-9]*',
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              sx={{ color: theme.palette.primary.text, padding: '0px' }}
                              onClick={() => dispatch(set_bet_amount(parseInt(bet_amount) - 5))} //{bet_amountx == null ? () => dispatch(set_bet_amount(parseInt(bet_amount) - 5)) : ()=>{console.log(bet_amountx)}}
                              edge="end"
                            >
                              <Iconify style={{ width: '32px', height: '32px' }} icon="ci:minus-square" />
                            </IconButton>
                          </InputAdornment>
                        ),
                        startAdornment: (
                          <InputAdornment position="start" sx={{ padding: '0px' }}>
                            <IconButton
                              sx={{ color: theme.palette.primary.text, padding: '0px' }}
                              onClick={() => dispatch(set_bet_amount(parseInt(bet_amount) + 5))} //{() => dispatch(set_bet_amount((bet_amountx && parseInt(bet_amountx) > 0) ? parseInt(bet_amountx) + 5 : parseInt(bet_amount) + 5))}
                              edge="end"
                            >
                              <Iconify style={{ width: '32px', height: '32px' }} icon="material-symbols:add-box" />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <TextField
                      sx={{
                        input: {
                          textAlign: 'center',
                          fontWeight: 'bolder',
                          fontSize: '24px',
                          boxSizing: 'content-box',
                          '&:focus': { color: theme.palette.primary.text },
                        },
                        label: { color: theme.palette.primary.text, '&:focus': { color: theme.palette.primary.text } },
                        '& .MuiInputLabel-root.Mui-focused': {
                          color: theme.palette.primary.text,
                        },
                        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                          borderColor: theme.palette.primary.text,
                        },
                      }}
                      style={{ display: logged_in_user.is_fixed_winning ? 'block' : 'none' }}
                      error=""
                      name="winning"
                      label="Total Winning"
                      type="number"
                      value={fixed_winning}
                      onChange={(e) => dispatch(set_fixed_amount(e.target.value))}
                    />

                    {show_number_of_players && (
                      <TextField
                        sx={{
                          input: {
                            textAlign: 'center',
                            fontWeight: 'bolder',
                            fontSize: '24px',
                            boxSizing: 'content-box',
                          },
                          label: { color: theme.palette.primary.text },
                        }}
                        error=""
                        name="winning"
                        value={selectedBoards?.length > 0 ? selectedBoards?.length / 2 + '' : ''}
                        type="text"
                      />
                    )}

                    <TextField
                      sx={{
                        input: {
                          textAlign: 'center',
                          fontWeight: 'bolder',
                          fontSize: '24px',
                          boxSizing: 'content-box',
                          '&:focus': { color: theme.palette.primary.text },
                        },
                        label: { color: theme.palette.primary.text, '&:focus': { color: theme.palette.primary.text } },
                        '& .MuiInputLabel-root.Mui-focused': {
                          color: 'white',
                        },
                        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                          borderColor: theme.palette.primary.text,
                        },
                      }}
                      error=""
                      name="autoAdd"
                      label="ካርቴላ ምረጥ/ቁጥር"
                      //type="number"
                      value={board_number}
                      onKeyDown={_handleCartelaKeyDown}
                      onChange={(e) => set_board_number(Number(e.target.value))}
                      InputProps={{
                        inputMode: 'numeric',
                        pattern: '[0-9]*',
                      }}
                    />
                  </Stack>
                  {selectedBoards && selectedBoards.length > 0 && <CardSelector selected={true}/>}
                </StyledPaper>
              </Grid>
            </Grid>
          </Container>
        </Dialog>
      </>
    );
}

export default CartelaSelect