import React, { Component } from 'react';


// // HOC that applies the theme to the wrapped class component
// const withTheme = (WrappedComponent) => {
//   // This component is a function component (valid place to call hooks)
//   return function ThemeComponent(props) {
//     const theme = useTheme(); // Calling the hook inside a functional component
//     return <WrappedComponent {...props} theme={theme} />;
//   };
// };

import  WithThemeCompoent  from './WithThemeComponent';

class BingoBoard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      board: props.board,
      manualMode: props.manualMode,
      manualCall: props.manualCall,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.board !== prevState.board || nextProps.manualMode !== prevState.manualMode) {
      return {
        board: nextProps.board,
        manualMode: nextProps.manualMode,
        manualCall: nextProps.manualCall,
      };
    }
    return null;
  }

  render() {
    const { theme } = this.props;
    return (
      <div
        id="board"
        className="flex"
        style={{
          backgroundColor: theme.palette.primary.main, // Using theme color here
          borderRadius: '10px',
        }}
      >
        {Object.keys(this.state.board).map((letter) => {
          return (
            <div key={'board-row-' + letter} className="row no-wrap set-size text-center notranslate">
              <div className="col board-letter white-bg black-text">{letter}</div>
              {this.state.board[letter].map((number) => (
                <div
                  key={number.display}
                  className={number.active ? 'col ball active' : number.called ? 'col ball called' : 'col ball'}
                  style={number.called ?{color:theme.palette.primary.main} : {color:theme.palette.primary.text}}
                >
                  {this.state.manualMode ? (
                    <button onClick={() => this.state.manualCall(number)}>{number.number}</button>
                  ) : (
                    number.number
                  )}
                </div>
              ))}
            </div>
          );
        })}
      </div>
    );
  }
}

export default WithThemeCompoent(BingoBoard); // Wrap BingoBoard with withTheme HOC
