import { useState,useEffect } from 'react';
import { useSelector } from 'react-redux';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, MenuItem, Stack, IconButton, Popover,Switch,FormControlLabel,Typography, Tooltip, useTheme } from '@mui/material';
import Iconify from 'src/components/iconify/Iconify';
import SoundIcon from '@mui/icons-material/VolumeUp'; 
// ----------------------------------------------------------------------
import { styled } from '@mui/material/styles';
const LANGS = [
  {
    value: 'amharic',
    label: 'አማርኛ/Amharic',
    // icon: '/assets/icons/ic_flag_et.svg',
    icon: '/assets/icons/a.svg',
  },
  {
    value: 'tigrigna',
    label: 'ትግሪኛ/Tigrigna',
    icon: '/assets/icons/T.svg',
  },
  {
    value: 'oromifa',
    label: 'Afaan Oromo',
    icon: '/assets/icons/o.svg',
  },
  {
    value: 'wolyta',
    label: 'ወላይትኛ/Walaytigna',
    icon: '/assets/icons/w.svg',
  }
  // {
  //   value: 'fr',
  //   label: 'French',
  //   icon: '/assets/icons/ic_flag_fr.svg',
  // },
];

const currency_formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});


// Define the pulsate animation
const pulsateAnimation = `
  @keyframes pulsate {
    from: {opacity: 1},
        to: {opacity: 0}
  }
`;

// Create a styled component with the pulsating animation
const PulsatingIconButton = styled(IconButton)(({ theme, shouldpulsate }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: Boolean(shouldpulsate) ? 'red' : 'green',
  padding: 0,
  width: 40,
  height: 40,
  //animation: shouldpulsate ? `fade 1s infinite` : 'none',
}));

// ----------------------------------------------------------------------

import { useDispatch } from 'react-redux';
import { changeLanguage,disableVoiceStatus } from 'src/redux/slices/companySlice';
import { get_credit_for_user } from 'src/redux/slices/creditSlice';

export default function LanguagePopover() {
  const theme=useTheme();
  const [open, setOpen] = useState(null);
  const [lanIndex,setLanIndex] = useState(0);
  const current_user = useSelector(state=>state.auth.user);
  const _credit = useSelector(state=>state.credits.credit);

  const dispatch = useDispatch();


  useEffect(()=>{
    if(!_credit){
      dispatch(get_credit_for_user(current_user?.phone))
    }
  },[current_user]);



  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = (value) => {
    if(typeof value !== 'object'){
      setLanIndex(value);
      //i could set a global language variable
      dispatch(changeLanguage(value));
    }
    setOpen(null);
  };

  const handleFullScreen = async()=>{
    if(!document.fullscreenElement){
      await document.documentElement.requestFullscreen();
    }else if (document.exitFullscreen){
      await document.exitFullscreen();
    }
  }

  const [checked, setChecked] = useState(false);

  const handleChange = (event) => {
    dispatch(disableVoiceStatus(event.target.checked));
    setChecked(event.target.checked);
  };
const shouldpulsate = _credit?.remaining_credit < 1500;
  return (
    <>
      {/* <FormControlLabel
        control={<Switch checked={checked} onChange={handleChange} inputProps={{ 'aria-label': 'controlled' }} />}
        label={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <SoundIcon fontSize="small" sx={{ color: 'gray' }} />
          </div>
        }
        labelPlacement="start"
      /> */}
      {/* <IconButton
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 20,
          height: 20,
          ...(open && {
            bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity),
          }),
        }}
      >
        <img src={LANGS[lanIndex]?.icon || LANGS[0]?.icon} alt={LANGS[lanIndex]?.label || LANGS[0]?.label} />
      </IconButton> */}

      <IconButton
        onClick={handleFullScreen}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: 0,
          width: 40,
          height: 40,
          color:theme.palette.primary.main,
          ...(open && {
            bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity),
          }),
        }}
      >
        <Iconify icon={'ic:baseline-fullscreen'}/>
      </IconButton>
      {_credit && (
        <Tooltip title={`ያሎት ቀሪ ሂሳብ ${currency_formatter.format(_credit.remaining_credit)} ብር`}>
          <PulsatingIconButton shouldpulsate={_credit.remaining_credit < 1000}>
            <Iconify icon={'solar:money-bag-bold'} />
          </PulsatingIconButton>
        </Tooltip>
        // <span style={shouldpulsate ? {color:'maroon',fontSize:'8px',fontWeight:'bold'}: {color:'green',fontSize:'8px',fontWeight:'bold'}}>
        //   ${currency_formatter.format(_credit.remaining_credit)}
        // </span>
      )}

      {/* <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            mt: 1.5,
            ml: 0.75,
            width: 180,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Stack spacing={0.75}>
          {LANGS.map((option, index) => (
            <MenuItem
              key={option?.value}
              selected={option?.value === LANGS[lanIndex]?.value || LANGS[0]?.value}
              onClick={() => handleClose(index)}
            >
              <Box component="img" alt={option.label} src={option.icon} sx={{ width: 14, mr: 2 }} />
              {option.label}
            </MenuItem>
          ))}
        </Stack>
      </Popover> */}
    </>
  );
}
