import { Helmet } from 'react-helmet-async';
import { faker } from '@faker-js/faker';
// @mui
import { useTheme} from '@mui/material/styles';
import {InputAdornment,IconButton,MenuItem} from '@mui/material';

import { Grid, Container, Typography,  Stack,TextField,Button } from '@mui/material';
// components
import Iconify from '../components/iconify';
// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
} from '../sections/@dashboard/app';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get_dashboard_data, get_dashboard_data_by_branch,get_dashboard_data_per_agent_subagent,getTransactionDetailPerCompanyPerMonth } from 'src/redux/slices/transactionSlice';
import { getAgentUsers, get_user_info } from 'src/redux/slices/userSlice';
import CustomBinTable from './DashboardTable';
import { Clear, Print, Search } from '@mui/icons-material';


// ----------------------------------------------------------------------

const current_date = () => {
  var curr = new Date();
  curr.setDate(curr.getDate());
  var date = curr.toISOString().substring(0, 10);
  return date;
};

export default function DashboardAppPage() {
  const theme = useTheme();
  const dashboardData = useSelector(state=>state.transactionList.dashboard_data);
  const dashboard_data_per_branch = useSelector(state=>state.transactionList.dashboard_data_per_branch);
  const attendance_is_loading = useSelector(state=>state.transactionList.attendanceLoading);

  //const dashboard_data_per_company = useSelector(state=>state.transactionList.dashboard_data_per_company);
  
  const current_company = useSelector(state=>state.auth.current_company);
  const user = useSelector((state) => state.auth.user);

  const [expiryBannerVisible,setExpiryBannerVisible] = useState(false);
  const [remainginDays,setRemainingDays] = useState(null);

  const [startDate,setStartDate] = useState(current_date());
  const [endDate,setEndDate] = useState(current_date());
  const [isSuper,setIsSuper] = useState(user?.role === 'super' || user?.role==='root' || user?.role==='Agent' || user?.role==='SubAgent');

  const [agent,setAgent] = useState(null);
  const [subAgent,setSubAgent] = useState(null);

  const listOfAgents = useSelector(state=> state.auth.agent_lists);

  const dispatch = useDispatch();



  useEffect(()=>{
    if (current_company) {
      if (current_company.expiry_date) {
        var expiryDateRaw = current_company.expiry_date;
        var currentDateRaw = new Date();

        var expiryDate = new Date(expiryDateRaw).toISOString().split('T')[0];
        var currentDate = new Date(currentDateRaw).toISOString().split('T')[0];

        var msPerDay = 24 * 60 * 60 * 1000; // Number of milliseconds per day

        var remainingD = Math.ceil((new Date(expiryDate) - new Date(currentDate)) / msPerDay);
        if(remainingD <= 5){
          setRemainingDays(remainingD);
          setExpiryBannerVisible(true);
        }else{
          setExpiryBannerVisible(false);
        }
        
      }
    }
  });

  useEffect(()=>{
    if(listOfAgents?.length === 0){
      dispatch(getAgentUsers());
    }
  },[/*listOfAgents*/]);

  useEffect(()=>{
    const fetch_dash_board_data = async () => {

      await dispatch(get_dashboard_data());
      await dispatch(get_dashboard_data_by_branch());
      {isSuper && await dispatch(getTransactionDetailPerCompanyPerMonth({start:startDate,end:endDate}))}

    };
    fetch_dash_board_data();
  },[]);

  const handleFindClick = async () => {
    var dateObj = {start:startDate,end:endDate,agent:agent,subAgent:subAgent};
    await dispatch(getTransactionDetailPerCompanyPerMonth(dateObj));

    try {
      if ((agent && agent.length > 0) || (subAgent && subAgent.length > 0)) {
        let srch_role = null;
        let srch_usr_name = null;

        if (agent && agent.length > 0) {
          srch_role = 'Agent';
          srch_usr_name = agent;
        }
        if (subAgent && subAgent.length > 0) {
          srch_role = 'SubAgent';
          srch_usr_name = subAgent;
        }
        var data = { srch_role: srch_role, srch_usr_name: srch_usr_name };
        //console.log(data)
        await dispatch(get_dashboard_data_per_agent_subagent(data));
      }
    } catch (error) {
      console.log(error);
    }
    
  }

  const handleClearClick = () => {
    setStartDate(current_date());
    setEndDate(current_date());
    setSubAgent(null);
    setAgent(null);
  }

  const get_chart_data = () => {
    var chartData = [];
    dashboard_data_per_branch?.forEach(element => {
      const label = element._id ? element._id : '';
      chartData.push({
        label: label,
        value: element.totalAmount
      });
    });
    return chartData;
  };

  const createAgentList = (type) => {
    
    var agents = listOfAgents?.filter(t=>t.role === type)?.map((agent)=>{
          return <MenuItem key={agent.userName} value={agent.userName}>{agent.userName}</MenuItem>
    });
    agents.unshift(<MenuItem key="empty" value="">Clear</MenuItem>);
    return agents;
}

  return (
    <>
      <Helmet>
        <title> Dashboard | ሀሁ ቢንጎ </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" align='right' sx={{ mb: 5, color: theme.palette.primary.main }}>
          Dashboard {' '}
          <span style={{ color: theme.palette.primary.main }}>
            {expiryBannerVisible && `(Your System Will Expire in ${remainginDays} days!)`}
          </span>
        </Typography>

        <Grid container spacing={3}>
  {/* Left Column */}
  <Grid item xs={12} md={6}>
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6} md={6}>
        <AppWidgetSummary
          title="Today's Sales"
          total={dashboardData ? dashboardData.totalAmountDay : 0}
          icon={'ant-design:dollar-circle'}
          bgcolor="#fff"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <AppWidgetSummary
          title="Weekly Sales"
          total={dashboardData ? dashboardData.totalAmountWeek : 0}
          bgcolor="#fff"
          icon={'ant-design:euro-circle'}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <AppWidgetSummary
          title="Monthly Sales"
          total={dashboardData ? dashboardData.totalAmountMonth : 0}
          bgcolor="#fff"
          icon={'ant-design:pound-circle'}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <AppWidgetSummary
          title="Year To Date"
          total={dashboardData ? dashboardData.totalAmountYear : 0}
          bgcolor="#fff"
          icon={'ant-design:bank'}
        />
      </Grid>
    </Grid>
  </Grid>

  {/* Right Column */}
  <Grid item xs={12} md={6}>
    <Grid container spacing={3}>
      {isSuper && (
        <>
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              onChange={(e) => setStartDate(e.target.value)}
              id="date"
              label="Start Date"
              type="date"
              value={startDate}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              onChange={(e) => setEndDate(e.target.value)}
              id="date"
              label="End Date"
              type="date"
              value={endDate}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          {user.role === 'super' ? (
            <>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  labelId="agent"
                  select
                  value={agent}
                  label="Agent"
                  defaultValue=""
                  onChange={(e) => setAgent(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton>
                          <Iconify icon="mdi:face-agent" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  sx={{ width: '100%' }}
                >
                  {createAgentList('Agent')}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={6} md={6} flexGrow={1}>
                <TextField
                  labelId="subAgent"
                  select
                  value={subAgent}
                  label="Sub Agent"
                  defaultValue=""
                  onChange={(e) => setSubAgent(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton>
                          <Iconify icon="material-symbols:support-agent" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  sx={{ width: '100%' }}
                >
                  {createAgentList('SubAgent')}
                </TextField>
              </Grid>
            </>
          ) : (
            user.role === 'Agent' && (
              <Grid item xs={12} sm={6} md={6} flexGrow={1}>
                <TextField
                  labelId="subAgent"
                  select
                  value={subAgent}
                  label="Sub Agent"
                  defaultValue=""
                  onChange={(e) => setSubAgent(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton>
                          <Iconify icon="material-symbols:support-agent" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  sx={{ width: '100%' }}
                >
                  {createAgentList('SubAgent')}
                </TextField>
              </Grid>
            )
          )}
          <Grid item xs={12} sm={6} md={6}>
            <Button
              sx={{
                width: '100%',
                height: '50px',
                backgroundColor: theme.palette.primary.main,
                '&:hover': {
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.primary.text,
                },
              }}
              variant="contained"
              endIcon={<Search />}
              onClick={handleFindClick}
            >
              Find
            </Button>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Button
              sx={{
                width: '100%',
                height: '50px',
                backgroundColor: theme.palette.primary.main,
                '&:hover': {
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.primary.text,
                },
              }}
              variant="contained"
              endIcon={<Clear />}
              onClick={handleClearClick}
            >
              Clear
            </Button>
          </Grid>
        </>
      )}
      {isSuper && (
        <Grid item xs={12}>
          <CustomBinTable
            type="sales"
            column_data={['SN', 'Company', 'Winnings', 'Total Cut', 'Total Trxs']}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <AppCurrentVisits
          title="Sales Percentage By Branch For Current Month"
          title_color={theme.palette.primary.main}
          chartData={get_chart_data()}
          chartColors={[
            theme.palette.primary.main,
            theme.palette.info.main,
            theme.palette.warning.main,
            theme.palette.error.main,
          ]}
        />
      </Grid>
      {(user?.role === 'super' || user?.role === 'Agent' || user?.role === 'SubAgent') && (
        <Grid item xs={12}>
          <h6 style={{ color: theme.palette.primary.main }}>{attendance_is_loading ? 'Loading Attendance ...' : 'Attendance'}</h6>
          <CustomBinTable
            type="attendance"
            column_data={['SN', 'Company', 'Date', 'Cashier', 'Status']}
          />
        </Grid>
      )}
    </Grid>
  </Grid>
</Grid>

      </Container>
    </>
  );
}
