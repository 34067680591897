import React from 'react';
import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions,
  Typography, 
  Grid, 
  Box, 
  IconButton,
  Divider,
} from '@mui/material';
import { styled } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';

const Watermark = styled(Box)(({ isVoid }) => ({
  position: 'absolute',
  top: '40%',
  left: 0,
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  opacity: 0.3,
  fontSize: '7rem',
  fontWeight: 'bold',
  color: 'gray',
  pointerEvents: 'none',
  zIndex: 0,
}));

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.text,
  padding: theme.spacing(2),
  textAlign: 'center',
  borderBottom: `1px dashed ${theme.palette.primary.main}`,
  fontFamily: 'monospace',
}));

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
  padding: theme.spacing(3),
  position: 'relative',
  zIndex: 1,
}));

const DetailItem = ({ label, value }) => (
  <Grid container justifyContent="space-between" sx={{ marginBottom: 2, fontFamily: 'monospace' }}>
    <Grid item>
      <Typography variant="body2" sx={{ fontWeight: 'bold' }}>{label}</Typography>
    </Grid>
    <Grid item>
      <Typography variant="body2" align="right">{value}</Typography>
    </Grid>
  </Grid>
);

const SalesDetailModal = ({ open, onClose, salesDetail }) => {
  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    });
  };

  const currentYear = new Date().getFullYear();

  return (
    <Dialog 
      open={open} 
      onClose={onClose} 
      fullWidth 
      maxWidth="sm"
      PaperProps={{
        style: {
          borderRadius: 8,
          border: '1px dashed #888',
          fontFamily: 'monospace'
        }
      }}
    >
      <StyledDialogTitle>
        Sales Transaction
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'white',
          }}
        >
          <CloseIcon />
        </IconButton>
      </StyledDialogTitle>
      
      <StyledDialogContent>
        <Watermark isVoid={salesDetail.is_void}>
          {salesDetail.is_void ? 'VOID' : 'VALID'}
        </Watermark>

        <DetailItem label="Transaction ID" value={salesDetail?.trx_id} />
        <Divider />
        <DetailItem label="Bet Amount" value={`$${salesDetail.bet_amount?.toFixed(2)}`} />
        <DetailItem label="Number of Players" value={salesDetail.number_of_players} />
        <DetailItem label="Total Winning" value={`$${salesDetail.total_winning?.toFixed(2)}`} />
        <Divider />
        <DetailItem label="Owner Cut" value={`${salesDetail.owner_cut_percentage}% (${salesDetail.owner_cut_amount?.toFixed(2)}$)`} />
        <DetailItem label="Player Winning" value={`$${salesDetail.player_winning?.toFixed(2)}`} />
        <DetailItem label="Branch" value={salesDetail.branch} />
        <DetailItem label="Cashier" value={salesDetail.cashier} />
        <Divider />
        <DetailItem label="Transaction Date" value={formatDate(salesDetail.trx_date)} />
        <DetailItem label="Playing Board IDs" value={salesDetail.playing_board_ids?.join(', ')} />
        <DetailItem label="Call List" value={salesDetail.call_list?.join(', ')} />
        <DetailItem label="Winning Board IDs" value={salesDetail.winning_board_id?.length > 0 ? salesDetail.winning_board_id?.join(', ') : 'None'} />
      </StyledDialogContent>

      <DialogActions sx={{ justifyContent: 'center', padding: 2, backgroundColor: '#F5F5F5' }}>
        <Typography variant="caption" color="textSecondary" sx={{ fontFamily: 'monospace' }}>
          © {currentYear} Hahu Bingo. All rights reserved.
        </Typography>
      </DialogActions>
    </Dialog>
  );
};     

export default SalesDetailModal;
