import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
// @mui
import { styled, alpha } from '@mui/material/styles';
import { Box, Link, Button, Drawer, Typography, Avatar, Stack } from '@mui/material';
// mock
import account from '../../../_mock/account';
// hooks
import useResponsive from '../../../hooks/useResponsive';
// components
import Logo from '../../../components/logo';
import Scrollbar from '../../../components/scrollbar';
import NavSection from '../../../components/nav-section';
//
import navConfig from './config';
import { useTheme } from '@mui/material';

// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

const StyledAccount = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

// ----------------------------------------------------------------------

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

import { useSelector,useDispatch } from 'react-redux';
import { setMenueCollapsed } from 'src/redux/slices/appStateSlice';
import { logout } from 'src/redux/slices/userSlice';

export default function Nav({ openNav, onCloseNav }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  
  const cola = useSelector(state=>state.appState.menu_collapsed);
  const user = useSelector(state=>state.auth.user);
  
  const isDesktop = useResponsive('up', 'lg');

  useEffect(() => {
    if (openNav) {
      //onCloseNav();
    }
    
  }, [pathname]);

  const handleLogout = () =>{
    dispatch(logout());
  }

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        bgcolor: 'primary.main', // navbar background color -----------------------
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      
      <Box sx={{ px: 2.5, py: 3, display: 'inline-flex' }}>
        <Logo />
      </Box>

      <Box sx={{ mb: 5, mx: 2.5, bgcolor: '#fff',borderRadius:'10px' }}>  
        <Link underline="none">
          <StyledAccount>
            <Avatar src={account.photoURL} alt="photoURL" />

            <Box sx={{ ml: 2 }}>
              <Typography variant="subtitle2" sx={{ color: theme.palette.primary.main }}>
                {/* {account.displayName} */}
                {user?.userName}
              </Typography>

              <Typography variant="body2" sx={{ color:  theme.palette.primary.main }}>
                {user?.role}
              </Typography>
            </Box>
          </StyledAccount>
        </Link>
      </Box>
      <NavSection data={navConfig} />

      {/* User username and profile box ------------------- */}
      

      <Box sx={{ flexGrow: 1 }} />

      <Box sx={{ px: 2.5, pb: 3, mt: 10 }}>
        <Stack alignItems="center" spacing={3} sx={{ pt: 5, borderRadius: 2, position: 'relative' }}>

          <Button 
            sx={{
              backgroundColor: theme.palette.primary.text,
              color: theme.palette.primary.primary,
              '&:hover': {
                backgroundColor: 'red',
                color: 'white',
              },
              '&.MuiButton-contained': {
                backgroundColor: 'white',
                color: 'red',
              },
              '&.MuiButton-contained:hover': {
                backgroundColor: theme.palette.primary.text,
                color: theme.palette.primary.main,
              },
            }}
            variant="contained" 
            onClick={handleLogout}  >
            Log Out
          </Button>
        </Stack>
      </Box>
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: (cola || !isDesktop) ? 0 : NAV_WIDTH },
      }}
    >
      {isDesktop ? (
        <Drawer
        open
          variant="permanent"
          PaperProps={{
            sx: {
              width: cola ? 0 : NAV_WIDTH,
              bgcolor: 'background.default',
              borderRightStyle: 'dashed',
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={()=>dispatch(setMenueCollapsed())}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: { width: NAV_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}
