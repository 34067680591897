import {createSlice,createAsyncThunk} from '@reduxjs/toolkit';
import companyService from 'src/services/companyService';


const initialState = {
    companies:[],
    isCompanySaved:true,
    isCompanySaving:false,
    company_state_updated:false,
    bingo_play_language:0,
    enable_voice_status : false,
}

export const create_company = createAsyncThunk(
    'createCompany',
    async(company,thunkAPI)=>{
        try {
            const result = await companyService.create_company(company);
            return result;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

export const get_all_companies = createAsyncThunk('get_all_companies',
    async(thunkAPI)=>{
        try {
            const result = await companyService.get_all_companies();
            return result;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
)

export const update_company_state = createAsyncThunk('update_company_state',
    async(comp_info,thunkAPI)=>{
        try {
            const result = await companyService.update_company_state(comp_info);
            return result;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);


export const delete_company_info = createAsyncThunk('delete_company_info',
    async(comp_info,thunkAPI)=>{
        try {
            console.log('delete_company_info');
            const result = await companyService.delete_company_info(comp_info);
            return result;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);


export const companySlice = createSlice({
    name:'companies',
    initialState,
    reducers:{
        changeLanguage:(state,action)=>{
            state.bingo_play_language = action.payload;
            console.log('language is: ' + action.payload);
        },
        disableVoiceStatus:(state,action)=>{
            state.enable_voice_status = action.payload;
            console.log('voice is disabled: '  + action.payload);
        },
    },
    extraReducers:{
        [create_company.fulfilled]:(state,action)=>{
            state.companies = state.companies.filter(t=>t.company_id !== action.payload.data.company_id);
            state.companies.push(action.payload.data);
            state.companies = state.companies?.sort((a, b) => b.created_at - a.created_at);
            state.isCompanySaved = true;
            state.isCompanySaving=false;
            console.log(state.companies);
        },
        [create_company.rejected]:(state,action)=>{
            state.isCompanySaved = false;
            state.isCompanySaving=false;
        },
        [create_company.pending]:(state,action)=>{
            state.isCompanySaving = true;
        },
        [get_all_companies.fulfilled]:(state,action)=>{
            //console.log(action.payload.data);
            state.companies = action.payload.data?.sort((a, b) => b.created_at - a.created_at);
        },
        [get_all_companies.pending]:(state,action)=>{

        },
        [get_all_companies.rejected]:(state,action)=>{

        },
        [update_company_state.fulfilled]:(state,action)=>{
            console.log(action.payload.data);
            state.company_state_updated = true;
            const comp = state.companies.find(comp => comp.company_id === action.payload.data.company_id);
            comp.isActive = action.payload.data.isActive;
        },
        [update_company_state.rejected]:(state,action)=>{
            console.log(action.payload);
            state.company_state_updated = false;
        },
        [delete_company_info.fulfilled]:(state,action)=>{
            console.log('deleted company is: ' + action.payload);
            console.log(action.payload.data.company_id);
            state.companies = state.companies.filter(t=>t.company_id !== action.payload.data.company_id);
        },
        [delete_company_info.rejected]:(state,action)=>{
            
        },
    }
});

export const{changeLanguage,disableVoiceStatus} = companySlice.actions;
const {reducer} = companySlice;
export default reducer;