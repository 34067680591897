import React, { useState } from 'react';
import { styled } from '@mui/material/styles';

const NumEntry = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
}));

const EntryField = styled('input')(({ theme }) => ({
  outline: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  fontSize: '32px',
  height: '52px',
  border: '1px solid gray',
  color: 'green',
  fontWeight:'bold',
  '&::-webkit-inner-spin-button': { '-webkit-appearance': 'none' },
}));

const NumberEntry = ({ numbers, handleChange }) => {
    
  return (
    <>
      <BingoLetters />
      <NumEntry>
        {numbers.map((row, rowIndex) => (
          <div key={rowIndex} className="row">
            {row.map((col, colIndex) => (
              <div key={colIndex} className="col">
                <EntryField 
                type="number" min={1} max={75} value={col} onChange={handleChange(rowIndex, colIndex)} 
                onKeyDown={(e) =>["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                />
              </div>
            ))}
          </div>
        ))}
      </NumEntry>
    </>
  );
};

const BingoLetterDisplay = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
}));

const BingoLetter = styled('div')(({ theme }) => ({
  fontSize: '32px',
  fontWeight: 'bold',
}));

function BingoLetters() {
  return (
    <BingoLetterDisplay>
      <BingoLetter style={{ color: 'Indigo' }}>B</BingoLetter>
      <BingoLetter style={{ color: 'crimson' }}>I</BingoLetter>
      <BingoLetter style={{ color: 'Orange' }}>N</BingoLetter>
      <BingoLetter style={{ color: 'blue' }}>G</BingoLetter>
      <BingoLetter style={{ color: 'maroon' }}>O</BingoLetter>
    </BingoLetterDisplay>
  );
}

export default NumberEntry;
