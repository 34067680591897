import PropTypes from 'prop-types';
import { NavLink as RouterLink } from 'react-router-dom';
// @mui
import { Box, List, ListItemText } from '@mui/material';
//
import { StyledNavItem, StyledNavItemIcon } from './styles';

import { useSelector } from 'react-redux';

// ----------------------------------------------------------------------



NavSection.propTypes = {
  data: PropTypes.array,
};

export default function NavSection({ data = [], ...other }) {
  const user = useSelector((state) => state.auth.user);
  const _credit = useSelector(state=>state.credits.credit);


  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1 }}>
        {/* {data.map((item) =>
          user?.role == 'super' || user?.role == 'Agent' ? (
            <NavItem key={item.title} item={item} />
          ) : user?.role == 'Admin' && !item.is_super_only ? (
            <NavItem key={item.title} item={item} />
          ) : user?.role == 'Employee' && !item.is_admin_only ? (
            user.userName.includes('.sales') ? (
              item.is_sales && <NavItem key={item.title} item={item} />
            ) : (
              !item.is_sales && <NavItem key={item.title} item={item} />
            )
          ) : null
        )} */}

        {data.map((item) => {

          if(item.title === 'Credit Balance' && !_credit){return null;}
          if (
            user?.role === 'super' ||
            user?.role === 'Agent' ||
            user?.role === 'SubAgent' ||
            (user?.role === 'Admin' && !item.is_super_only) ||
            (user?.role === 'Employee' && !item.is_admin_only)
          ) {
            if (user?.role === 'Employee') {
              if (user.userName.includes('.sales')) {
                if (item.is_sales || item.is_credit) {
                  return <NavItem key={item.title} item={item} />;
                }
              }else if(item.is_emp_only){
                return <NavItem key={item.title} item={item} />;
              }
               else {
                if (!item.is_sales && !item.is_credit) {
                  return <NavItem key={item.title} item={item} />;
                }
              }
            } else {
              if(!item.is_emp_only || user?.role === 'super'){
                return <NavItem key={item.title} item={item} />;
              }
              
            }
          }
          return null;
        })}

        {/* {data.map((item) => {
          const canDisplayItem = () => {
            if (user?.role === 'super') {
              return true;
            }
            if ((user?.role === 'Admin' || user?.role === 'Agent') && !item.is_super_only) {
              return true;
            }
            if (user?.role === 'Employee' && !item.is_admin_only) {
              if (user.userName.includes('.sales')) {
                return item.is_sales;
              } else {
                return !item.is_sales;
              }
            }
            return false;
          };

          if (canDisplayItem()) {
            return <NavItem key={item.title} item={item} />;
          }
          return null;
        })} */}
      </List>
    </Box>
  );
}

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
};

function NavItem({ item }) {
  const { title, path, icon, info } = item;

  return (
    <StyledNavItem
      component={RouterLink}
      to={path}
      sx={{
        '&:hover' : {
          color: '#999999'
        },
        color: 'primary.text', // All Navitems          ------------------------------------
        '&.active': {
          color: 'primary.active', // Selected Navitem -----------------------
          bgcolor: 'action.selected',
          fontWeight: 'fontWeightBold',
        },
      }}
    >
      
      <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>
      <ListItemText disableTypography primary={title} />

      {info && info}
      {/* <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon> */}
    </StyledNavItem>
  );
}
