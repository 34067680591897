import React, { useState } from 'react';
import './RefundPattern.css'; // Import your CSS file
import { useDispatch } from 'react-redux';
import { set_refund_pattern } from 'src/redux/slices/transactionSlice';

const RefundPattern = () => {

    
  const dispatch = useDispatch();

  // Define initial state for the selected cells
  const [selectedCells, setSelectedCells] = useState({
    B: { '0': false, '1': false, '2': false, '3': false, '4': false },
    I: { '0': false, '1': false, '2': false, '3': false, '4': false },
    N: { '0': false, '1': false, '2': false, '3': false, '4': false },
    G: { '0': false, '1': false, '2': false, '3': false, '4': false },
    O: { '0': false, '1': false, '2': false, '3': false, '4': false },
  });

  // Handle click event on a cell
  const handleCellClick = (letter, index) => {
    // Update the state to mark the cell as selected or do your logic here
    console.log(`${letter}-${index}`);
    setSelectedCells((prevSelectedCells) => {
        const updatedCells = {
          ...prevSelectedCells,
          [letter]: {
            ...prevSelectedCells[letter],
            [index]: !prevSelectedCells[letter]?.[index],
          },
        };
        //console.log(updatedCells);

        const hasTrueValue = Object.keys(updatedCells).some((letter) => {
            return Object.values(updatedCells[letter]).some((value) => value === true);
          });

          if(hasTrueValue){
            dispatch(set_refund_pattern(updatedCells));
          }else{
            dispatch(set_refund_pattern(null));
          }
       
        return updatedCells;
      });
    //console.log(selectedCells);
  };

  return (
    <div className="bingo-container">
      <div className="bingo-header">
        {['B', 'I', 'N', 'G', 'O','(','R','E','F','U','N','D',')'].map((letter,index) => (
          <div key={`${letter}${index}`} className="header-cell">
            {letter}
          </div>
        ))}
      </div>
      <div className="bingo-grid">
        {['B', 'I', 'N', 'G', 'O'].map((letter) => (
          <div key={letter} className="bingo-column">
            {Object.keys(selectedCells[letter]).map((index) => (
              <div
                key={letter + index}
                className={selectedCells[letter][index] ? 'selected cell' : 'cell'}
                onClick={() => handleCellClick(letter, index)}
              >
                {letter === 'N' && index === '2' ? (
                  <span className="free-space">*</span>
                ) : (
                  <span>&nbsp;</span>
                )}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default RefundPattern;
