import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

// SETUP COLORS
const GREY = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
};

const INFO = {
  lighter: '#D0F2FF',
  light: '#74CAFF',
  main: '#1890FF',
  dark: '#0C53B7',
  darker: '#04297A',
  contrastText: '#fff',
};

const SUCCESS = {
  lighter: '#E9FCD4',
  light: '#AAF27F',
  main: '#54D62C',
  dark: '#229A16',
  darker: '#08660D',
  contrastText: GREY[800],
};

const WARNING = {
  lighter: '#FFF7CD',
  light: '#FFE16A',
  main: '#FFC107',
  dark: '#B78103',
  darker: '#7A4F01',
  contrastText: GREY[800],
};

const ERROR = {
  lighter: '#FFE7D9',
  light: '#FFA48D',
  main: '#FF4842',
  dark: '#B72136',
  darker: '#7A0C2E',
  contrastText: '#fff',
};


const PRIMARY_MAROON = {
  lighter: '#FCE4EC',
  light: '#ad0000',
  main: '#800000',  // Maroon
  active: '#fff',
  darker: '#4E0010',
  contrastText: '#fff',
  text:'#fff',
  borderColor:'#fff'
};

const PRIMARY_POWER = {
  lighter: '#D4EDDA',
  light: '#009900',
  main: '#007F00',  // Power company primary green
  active: '#000',
  darker: '#001F00',
  contrastText: '#fff',
  text:'#f5e92a',
  borderColor:'#808080'
};

const palette = {
  common: { black: '#000', white: '#fff' },
  primary: PRIMARY_MAROON,
  secondary: PRIMARY_MAROON,
  info: INFO,
  success: SUCCESS,
  warning: WARNING,
  error: PRIMARY_MAROON,
  grey: GREY,
  divider: alpha(GREY[500], 0.24),
  text: {
    primary: GREY[800],
    secondary: GREY[600],
    disabled: GREY[500],
  },
  background: {
    paper: '#fff',
    default: GREY[100],
    neutral: GREY[200],
  },
  action: {
    active: GREY[600],
    hover: alpha(GREY[500], 0.08),
    selected: alpha(GREY[500], 0.16),
    disabled: alpha(GREY[500], 0.8),
    disabledBackground: alpha(GREY[500], 0.24),
    focus: alpha(GREY[500], 0.24),
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};

export { PRIMARY_POWER }; // Export the PRIMARY_POWER palette
export default palette;
