
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { set_selected_pattern } from 'src/redux/slices/transactionSlice';

const Pattern = (props) => {
  const [selectedPattern, setSelectedPattern] = useState(props.pattern.pattern);
  const dispatch = useDispatch();

  const handlePatternUpdate = (letter, index,rowIndex,colIndex) => {

    const updatedPattern = {
      ...selectedPattern,
      [letter]: {
        ...selectedPattern[letter],
        [index]: !selectedPattern[letter][index]
      }
    };
    dispatch(set_selected_pattern(updatedPattern));
    setSelectedPattern(updatedPattern);
  };

  return (
    <div id="bingopattern" className="notranslate">
      {Object.keys(selectedPattern).map((letter, rowIndex) => {
        return (
          <div key={letter + rowIndex} className="vertical-row text-center">
            <div className="col maroon-bg white-text"><span>{letter}</span></div>
            {Object.keys(selectedPattern[letter]).map((number, colIndex) => {
              const isSelected = selectedPattern[letter][number];
              const classNames = isSelected ? 'selected col' : 'col';
              return (
                <div
                  key={letter + number}
                  className={classNames}
                  onClick={(e) => handlePatternUpdate(letter, number,rowIndex,colIndex)}
                >
                  {letter === 'N' && colIndex === 2 ? (
                    <span className="free-space">ነጻ</span>
                  ) : (
                    <span>&nbsp;</span>
                  )}
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export default Pattern;





