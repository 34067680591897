import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import BingoCard from '../components/bingo/subcomponents/BingoCard';
import { useDispatch,useSelector } from 'react-redux';

import {create_boards, delete_board_by_id, reset_board_saved, sync_boards} from 'src/redux/slices/boardSlice';
import CardEntryForm from 'src/components/bingo/subcomponents/CardEntryForm';
import { get_all_boards } from 'src/redux/slices/boardSlice';
import CustomizedSnackbars from 'src/components/bingo/subcomponents/SnackBar';
import Stack from '@mui/material/Stack';
import { get_all_companies } from 'src/redux/slices/companySlice';
import Checkbox from '@mui/material/Checkbox';
import { useTheme } from '@mui/material';



export default function CardGenerator(){

  const theme = useTheme();
  const [generatedCards,setGeneratedCards] = useState([]);
  const [reterivedCards,setReterivedCards] = useState([]);
  const [numberOfCards,setNumberOfCards] = useState(null);
  const [blackWhite,setBlackWhite] = useState(false);
  const [color,setColor] = useState(null);
  const [perPage,setPerPage] = useState(null);
  const [cards_to_save,setCards_to_save] = useState([]);
  const [openAddNew,setOpenAddnew] = useState(false);
  const [forBranch,setForBranch] = useState('');
  const all_boards = useSelector(state=>state.boards.boards);
  const board_saved = useSelector(state=> state.boards.board_saved);
  const board_save_has_error = useSelector(state=>state.boards.board_save_has_error);
  const companies = useSelector((state) => state.companies.companies);
  const loged_in_user = useSelector(state => state.auth.user);;

  const sync_status = useSelector(state=>state.boards.sync_status);


  
  const [snackOpen,setSnackOpen] = useState(false);
  const [snackMessage,setSnackMessage] = useState('');



  const dispatch = useDispatch();

  useEffect(() => {
   
    async function fetchCompanies() {
      try {
        dispatch(get_all_companies());
      } catch (error) {
        console.log(error);
      }
    }
    if (!companies || companies.length == 0) {
      fetchCompanies();
    }
  }, []);

  useEffect(()=>{
    showCards();
  },[all_boards]);

  useEffect(() => {

    if (board_saved || board_save_has_error) {
      setSnackOpen(true);
      if (board_saved) {
        setSnackMessage('Board Saved!');
      }
      if (board_save_has_error) {
        setSnackMessage('Something Went Wrong!');
      }
    }
      

  },[board_saved,board_save_has_error]);


  

  const handleNumberSelect = (event) => {
     setNumberOfCards(parseInt(event.value));
  }
  const handleColorSelect = (event) => {
    setColor(event.value);
  }
  const handlePerPageSelect = (event) => {
    setPerPage(event);
  }
  const handleBWCheckbox = (e) => {
    setBlackWhite(e.currentTarget.checked);
  }

  const handleForBranchChanged = (event)=>{
    
      setForBranch(event.value);
  }

 

  function showSavedCards(){
    console.log(generatedCards);
    setGeneratedCards([]);
    //get all cards
    dispatch(get_all_boards(forBranch));
    //setGeneratedcards
  }

  const handleSaveCards = () => {
    const params = {boards:cards_to_save,name:null,branch:forBranch};
    dispatch(create_boards(params));
  }

  
  const closeAddNewDialog = () => {
    setOpenAddnew(false);
  }

  const handleCreateNewBoard = () => {
    setOpenAddnew(true);
  };

  const handleSyncBoards = async () => {
    //loged_in_user
    try{
      await dispatch(sync_boards(loged_in_user.company_id));
    }catch(error){
      console.log(error);
    }
  }

//method to generate new cards
  function handleButton(){
    dispatch(reset_board_saved());
    let cards = [];
    let cards_to_save = [];
    setReterivedCards([]);
    for(let i = 1; i <= numberOfCards; i++){
      const {card,flat_card} =  generateCard();
      cards.push(card);
      cards_to_save.push(flat_card);
    }
    console.log(cards);
    setGeneratedCards(cards);
    setCards_to_save(cards_to_save);
    setSnackOpen(false);
    setSnackMessage('');
  }

  const generateBingoNumbers = () => {
    let letters = ["B", "I", "N", "G", "O"];
    let numbers = {};
    let count = 1;
    letters.forEach((letter) => {
      numbers[letter] = [];
      for(let i = 1; i <= 15; i++) {
        numbers[letter].push(count);
        count++;
      }
    })
    console.log(numbers);
    return numbers;
  }
  const generateCard = () => {

    let numbers = generateBingoNumbers();
    let card = {}; 
    let savable_nums = [];

    Object.keys(numbers).map(letter => {
      let chosenNumbers = [];
      let flat_array = [];

      for(let i = 0; i<5; i++){
        let rand_num = numbers[letter].splice(Math.floor(Math.random()*numbers[letter].length), 1);
        chosenNumbers.push(rand_num);
        flat_array.push(rand_num[0]);
      }
      card[letter] = chosenNumbers;
      
      savable_nums.push(flat_array);
     
      return letter;
    });
    return {card:card,flat_card:savable_nums};
  }

  const showCards = () => {
    var cards_to_show = [];
    all_boards && all_boards.length > 0 && all_boards?.forEach((board,index)=>{
      let card = {}; 
      var tr = getSavedBingoNumbers(board.board_numbers);
      Object.keys(tr).map(letter => {
        card[letter] = tr[letter];
      });
      const card_info = {
        id:board._id,
        card:card,
        name:board.board_name + `- [${board.branch_id}]`
      }
      cards_to_show.push(card_info);
    })
    //console.log(cards_to_show)
    setReterivedCards(cards_to_show);
    return cards_to_show;
  }

  
  const getSavedBingoNumbers = (board_numbers) =>{
    let letters = ["B", "I", "N", "G", "O"];
    let numbers = {};
    letters.forEach((letter,index)=>{
      numbers[letter] = [];
      for(let i = 0;i<5;i++){
        var num = [];
        num.push(board_numbers[index][i]);
        numbers[letter].push(num);
      }
    });
    return numbers;
  }


  const printCards = () => {
    let style = document.createElement('style');
    switch(perPage.value){
      case "2":
        style.appendChild(document.createTextNode('@page { orientation: landscape; size: landscape; margin: 1in .5in; }'));
        break;
      case "4":
        style.appendChild(document.createTextNode('@page { orientation: portrait; size: portrait; margin: 1in .5in; }'));
        break;
      case "6":
        style.appendChild(document.createTextNode('@page { orientation: landscape; size: landscape; margin: 0.4in 0.25in; }'));
        break;
      default:
        style.appendChild(document.createTextNode('@page { orientation: portrait; size: portrait; margin: 1in .5in; }'));
        break;
    }
    document.head.appendChild(style);
  }



  function colorOptions(){
    return [
      {value: "red", label: "red"},
      {value: "orange", label: "orange"},
      {value: "yellow", label: "yellow"},
      {value: "green", label: "green"},
      {value: "blue", label: "blue"},
      {value: "purple", label: "purple"},
      {value: "pink", label: "pink"},
      {value: "aqua", label: "aqua"},
      {value: "gray", label: "gray"},
      {value: "brown", label: "brown"}
    ]
  }



  const generateButtonDisabled =()=>{
    try {
      
    return numberOfCards === null;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  function sectionClasses() {
    let classes = "padding-vertical-xxlg pale-gray-bg " + this.state.blackWhite === "true" ? "print-bw " : "print-color ";
    if(this.state.perPage !== null){
      switch(this.state.perPage.value){
        case "2":
          classes+= 'print-two ';
          break;
        case "4":
          classes+= 'print-four ';
          break;
        case "6":
          classes+= 'print-six ';
          break;
        default:
          classes+= 'print-four ';
          break;
      }
    }
    return classes;
  }

  const numberOfCardsOptions = () => {
    let options = [];
    for(let i = 0; i <= 100; i++){
      options.push({value: i.toString(), label: i.toString()});
    }
    return options;
  }

  const forBranchOptions = () => {
    
    let options = [];
    options.push({ value: null, label: 'NONE' });

    // companies[0]?.branches?.map((branch) => {
    //   options.push({ value: branch.branch_name, label: branch.branch_name });
    // });
    if(loged_in_user?.role === "Agent"){
      companies.forEach((company) => {
        options.push({ value: company.company_id, label: company.company_name });
      });
    }else{
      companies.forEach((company) => {
        company.branches.forEach((branch) => {
          options.push({ value: branch.branch_name, label: branch.branch_name });
        });
      });
    }
    return options;
  };

  const handleDeleteCard = (id) =>{
   
    console.log(id);
    dispatch(delete_board_by_id(id));
  }

  const handleCardSelect = (id) => {
    //dispatch(handle_selected_cards(id))
  }

  const handleSnackClose = () => {
    setSnackOpen(false);
  };

    return (
      <section className={sectionClasses}>

        {snackOpen && (board_saved || board_save_has_error) && (
          <CustomizedSnackbars
            snackOpen={snackOpen}
            snackMessage={snackMessage}
            handleSnackClose={handleSnackClose}
            is_saved={board_saved}
            is_failed={board_save_has_error}
            is_loading={false}
          />
        )}

        <CardEntryForm open={openAddNew} onClose={closeAddNewDialog} />
        <div className="container row no-print">
          <div className="col">

            <h2 style={{color: theme.palette.primary.main}}>Cartela Board Generator{reterivedCards?.length > 0 && ` (${reterivedCards?.length}) boards found`}</h2>

            <div className="row justify-start align-center extra-pale-gray-bg padding-xlg">
              <Stack>
                <Stack direction={{ xs: 'column', md: 'row' }}>
                  
                  <div className="col shrink padding-horizontal-md">
                    <Select
                      className="number-select"
                      placeholder={loged_in_user?.role === 'Agent' ? 'select company' : 'Select Branch'}
                      onChange={handleForBranchChanged}
                      options={forBranchOptions()}
                      style={{ width: '100%' }}
                    />
                  </div>

                  {/* <div className="col shrink padding-horizontal-md margin-right-xlg">
                  <button className="primaryBtn" onClick={handleButton} disabled={generateButtonDisabled()}>Generate Cards</button>
                  
                </div>
                <div className="col padding-horizontal-md text-right">
                  <button data-visibility={generatedCards.length > 0 ? 'show' : 'hide'} className="altBtn" onClick={handleSaveCards}>Save Cards</button>
                </div> */}

                  <div className="col padding-horizontal-md">
                    <button
                      style={{ width: '100%',borderColor:'transparent',borderRadius:'10px',backgroundColor:theme.palette.primary.main,color:theme.palette.primary.text }}
                      data-visibility={'show'}
                      className="altBtn"
                      onClick={showSavedCards}
                    >
                      Show Cards
                    </button>
                  </div>

                  <div className="col padding-horizontal-md">
                    <button
                      style={{ width: '100%',borderColor:'transparent',borderRadius:'10px',backgroundColor:theme.palette.primary.main,color:theme.palette.primary.text }}
                      data-visibility={'show'}
                      className="altBtn"
                      onClick={handleCreateNewBoard}
                    >
                      Create New Board
                    </button>
                  </div>

                  <div className="col padding-horizontal-md">
                    <button
                      style={{ width: '100%',borderColor:'transparent',borderRadius:'10px',backgroundColor:theme.palette.primary.main,color:theme.palette.primary.text }}
                      data-visibility={'show'}
                      className="altBtn"
                      onClick={handleSyncBoards}
                    >
                      Sync Boards
                    </button>
                  </div>
                  {/* setSnackMessage('Board Saved!'); */}
                  {sync_status && ( <div className="col padding-horizontal-md">
                    <button
                      style={{ width: '100%' }}
                      data-visibility={'show'}
                      className="altBtn"
                      //onClick={handleSyncBoards}
                    >
                     <span style={sync_status.includes('error') ? { color: 'red' } : { color: 'blue' }}>{sync_status}</span>
                    </button>
                  </div>)
                  }

                  {/* // {sync_status && (
                  //   <div className="col padding-horizontal-md">
                  //     <p style={sync_status.includes('error') ? { color: 'red' } : { color: 'blue' }}>{sync_status}</p>
                  //   </div>
                  // )} */}
                </Stack>
              </Stack>
            </div>
          </div>
        </div>
        <div className="card-block">
          <div className="col text-center">
            <Stack direction={{ xs: 'column', md: 'spacing:2' }}>
              {/* {generatedCards && generatedCards.map((card, index) => {
                  return( 
                    
                    <div data-color={blackWhite ? 'dk-gray' : 'yellow'} className="card" key={card?._id || "a" + index}>
                      <h3>{`Cartela ${index+1}`}</h3>
                      <BingoCard card={card} key={card?._id || index} />
                    </div>
                  )
                })} */}

                
              {reterivedCards &&
                reterivedCards.map(({ id, card, name }, index) => {
                  return (
                    <div style={{backgroundColor:theme.palette.primary.main}} className="card" key={id}>
                      <Stack direction={'row'}>
                      <Checkbox
                        onChange={()=> handleCardSelect(id)}
                        sx={{
                          '&.Mui-checked': {
                            color: theme.palette.primary.text, 
                          },
                        }}
                        />
                      <h3 style={{color: theme.palette.primary.text}}>{name}</h3>
                      </Stack>
                      <button style={{borderRadius:'10px'}}className="primaryBtn" onClick={() => handleDeleteCard(id)}>
                        Delete
                      </button>
                      <BingoCard card={card} key={id} />
                    </div>
                  );
                })}
               
            </Stack>
          </div>
        </div>
      </section>
    );
  }