import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//

import UserPage from './pages/UserPage';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import DashboardAppPage from './pages/DashboardAppPage';
import BingoGame from './components/bingo/BingoGame';
import CardGenerator from './pages/CardGenerator';
import Companies from './pages/Companies';
import { useSelector } from 'react-redux';


import RoleCheck from './roleCheck';
import SalesPage from './pages/Sales';
import BlogPage from './pages/BlogPage';
// ----------------------------------------------------------------------

export default function Router({user}) {

  //get user company information and redirect to login page if expiry date is exceeded.

  const renderForEmployee = (iscredit) => {
    if (user) {
      if (isAdmin || isSuper || isAgent) {
        return <Navigate to="/dashboard/app" />;
      } else if (user.userName.includes('.sales') && !iscredit) {
        return <Navigate to="/dashboard/sales" />;
      }else if (user.userName.includes('.sales') && iscredit) {
        return <Navigate to="/dashboard/credit" />;
      } 
      else {
        return <Navigate to="/dashboard/bingo" />;
      }
    } else {
      return <LoginPage />;
    }
  };


  const isAdmin = useSelector(state=>state.auth.isAdmin);
  const isSuper = useSelector(state=>state.auth.isSuper);
  const isAgent = useSelector(state=>state.auth.isAgent);

  const routes = useRoutes([
    {
      path: '/',
      element: user ? <DashboardLayout /> : <Navigate to="/login"/>,
      children: [
        { path: '/', element: (isAdmin||isSuper||isAgent) ? <DashboardAppPage /> : renderForEmployee(false)},
        { path: 'dashboard/', element: (isAdmin||isSuper||isAgent) ? <DashboardAppPage /> : renderForEmployee(false)},
        { path: 'dashboard/app', element: (isAdmin||isSuper||isAgent) ? <DashboardAppPage /> : renderForEmployee(false)},
        { path: 'dashboard/user', element: <RoleCheck isAdmin={(isAdmin||isSuper ||isAgent)} component={<UserPage />}/> },
        { path: 'dashboard/companies', element: <RoleCheck isAdmin={isSuper ||isAgent} component={<Companies />}/> },//if role is super admin only
       
        { path: 'dashboard/bingo',element: <BingoGame/>},
        { path: 'dashboard/credit',element: (isAdmin || isSuper || isAgent || user?.userName.includes('.sales')) ? <BlogPage/> : renderForEmployee(true)},
        { path: 'dashboard/sales', element:  <SalesPage />},//sales route
        { path: 'dashboard/cartela',element:<RoleCheck isAdmin={(isAdmin||isSuper ||isAgent)} component={<CardGenerator/>} /> }
      ],
    },
    {
      path: 'login',
      element:renderForEmployee(false)
    },
    {
      path: '404',
      element: <Page404 />
    },
    // {
    //   element: <SimpleLayout />,
    //   children: [
    //     { element: ((isAdmin||isSuper||isAgent) ? <Navigate to="/dashboard/app" /> : <Navigate to="/dashboard/sales"/>), index: true },
    //     { path: '404', element: <Page404 /> },
    //     { path: '*', element: <Navigate to="/404" /> },
    //   ],
    // },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
