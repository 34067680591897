import { useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox, useTheme } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Button from '@mui/material/Button';
// components
import Iconify from '../../../components/iconify';

//axios

import { useDispatch,useSelector } from 'react-redux';
import { login } from 'src/redux/slices/userSlice';


// ----------------------------------------------------------------------

export default function LoginForm() {

  const theme = useTheme();
  const [userName,setUserName] = useState('');
  const [password,setPassword] = useState('');
  const [usernameError,setUsernameError] = useState(null);
  const [passwordError,setPasswordError] = useState(null);

  const [showPassword, setShowPassword] = useState(false);


  const dispatch = useDispatch();
  const navigate = useNavigate();

  const login_error = useSelector((state)=>state.auth.login_error);

  const handleClick = async () => {

    setUsernameError(null);
    setPasswordError(null);
    if(!userName){
      setUsernameError('Please enter username!')
      return;
    }
    if(!password){
      setPasswordError('Please enter password!')
      return;
    }
    
    //login clicked
    const userData = {
        userName,
        password
    };
    await dispatch(login(userData)).then(()=>{
      navigate('/dashboard/app');
    });
  };

  return (
    <>
      <Stack spacing={3}>
        
        <TextField 
          sx={{
            input: {'&:focus': {color:theme.palette.primary.main}, '&::placeholder' : {color: theme.palette.primary.main} },
            label: { color: 'black', '&:focus' : {color: 'black'} },
            '& .MuiInputLabel-root.Mui-focused': {
              color: theme.palette.primary.main, 
            },
            '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: theme.palette.primary.main 
            },
          }}
          helperText={usernameError??usernameError}
          name="userName" 
          label="User Name" 
          onChange={(e)=> setUserName(e.target.value)}
          />

        <TextField
          sx={{
            input: {'&:focus': {color:theme.palette.primary.mai}, '&::placeholder' : {color: theme.palette.primary.mai} },
            label: { color: 'black', '&:focus' : {color: 'black'} },
            '& .MuiInputLabel-root.Mui-focused': {
              color: theme.palette.primary.mai, 
            },
            '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: theme.palette.primary.mai 
            },
          }}
          helperText={passwordError??passwordError}
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          onChange={(e)=> setPassword(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        {/* <Checkbox name="remember" label="Remember me" />
        <Link variant="subtitle2" underline="hover">
          Forgot password?
        </Link> */}
        <p style={{color:theme.palette.primary.mai,fontSize:'12px'}}>{login_error}</p>
      </Stack>

      <Button sx={{background: theme.palette.primary.main, color: theme.palette.primary.text, '&:hover' : {backgroundColor: theme.palette.primary.main,color:'#fff'},}}fullWidth size="large" type="submit" variant="contained" onClick={handleClick}>
        Login
      </Button>
    </>
  );
}
