import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import { Link, Container, Typography, Divider, Stack, Button, useTheme } from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Logo from '../components/logo';
import Iconify from '../components/iconify';
// sections
import { LoginForm } from '../sections/auth/login';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function LoginPage() {
  const mdUp = useResponsive('up', 'md');
  const theme = useTheme();
  return (
    <>
      <Helmet>
        <title> Login |  ሀሁ ቢንጎ </title>
      </Helmet>

      <div style={{background: `linear-gradient(to right, ${theme.palette.primary.main} 50%, ${theme.palette.primary.text} 50%)`}}>
        
        <StyledRoot>
          <Logo
            sx={{
              position: 'fixed',
              top: { xs: 16, sm: 24, md: 40 },
              left: { xs: 16, sm: 24, md: 40 },
            }}
          />

          {/* {mdUp && (
            <StyledSection>
              <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5, color: '#ad0000' }}>
                Hi, Welcome Back
              </Typography>
              <img src="/assets/illustrations/illustration_login.png" alt="login" />
            </StyledSection>
          )} */}

          <Container maxWidth="sm">
            <StyledContent>
              <div style={{backgroundColor: 'white', padding: '50px', boxShadow: '2px 3px 6px 2px rgba(0,0,0,0.2)', borderRadius: '10px'}}>
                <Typography sx={{color: theme.palette.primary.main, textAlign: 'center'}} variant="h4" gutterBottom>
                  Sign in to HaHu Bingo!
                </Typography>

                {/* <Typography variant="body2" sx={{ mb: 5 }}>
                  Don’t have an account? {''}
                  <Link variant="subtitle2">Get started</Link>
                </Typography> */}

                {/* <Stack direction="row" spacing={2}>
                  <Button fullWidth size="large" color="inherit" variant="outlined">
                    <Iconify icon="eva:google-fill" color="#DF3E30" width={22} height={22} />
                  </Button>

                  <Button fullWidth size="large" color="inherit" variant="outlined">
                    <Iconify icon="eva:facebook-fill" color="#1877F2" width={22} height={22} />
                  </Button>

                  <Button fullWidth size="large" color="inherit" variant="outlined">
                    <Iconify icon="eva:twitter-fill" color="#1C9CEA" width={22} height={22} />
                  </Button>
                </Stack> */}

                <Divider sx={{ my: 3, color:theme.palette.primary.main }}>
                  <Typography variant="body2" sx={{ color: theme.palette.primary.main }}>
                    Login
                  </Typography>
                </Divider>

                <LoginForm />
              </div>
            </StyledContent>
          </Container>
        </StyledRoot>
      </div>
    </>
  );
}
