// HOC that applies the theme to the wrapped class component


import { useTheme } from '@mui/material/styles'; // Hook to access theme
const WithThemeCompoent = (WrappedComponent) => {
    // This component is a function component (valid place to call hooks)
    return function ThemeComponent(props) {
      const theme = useTheme(); // Calling the hook inside a functional component
      return <WrappedComponent {...props} theme={theme} />;
    };
  };

  export default WithThemeCompoent;