import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox,
     Select, FormControl, InputLabel, MenuItem, Dialog,Container,Typography,Divider, FormLabel, 
     useTheme} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../iconify';

import { styled } from '@mui/material/styles';

import { useDispatch,useSelector } from 'react-redux';
import { createUser, updateUser } from 'src/redux/slices/userSlice';
import { useEffect } from 'react';

import './textfieldproperties.css';



const StyledContent = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
  }));

// ----------------------------------------------------------------------

export default function RegisterForm({openDialog,handleDialogClose,roles,user,phoneEditLock}) {
    const theme = useTheme();
    const [company_id,setCompany] = useState('');
    const [fullName, setFullName] = useState('');
    const [userName, setUserName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [systemCut,setSystemCut] = useState(25);
    const [playPercentage,setPlayPercentage] = useState(null);
    const [betAmount,setBetAmount] = useState(null);
    const [role, setRole] = useState('Employee');
    const [branch, setBranch] = useState('');
    const [password, setPassword] = useState('');
    const [isActive, setIsActive] = useState(true);
    
    const [isManualTrx,setIsManualTrx] = useState(false);
    const [isFixedWinning,setIsFixedWinning] = useState(false);

    const [selectedCompany,setSelectedCompany] = useState(null);

    


    const [companyError, setCompanyError] = useState(false);
    const [fullNameError, setFullNameError] = useState(false);
    const [userNameError, setuserNameError] = useState(false); 
    const [userNameErrorText,setUserNameErrorText] = useState('');
    const [phoneNumberError,setPhoneNumError] = useState(false);
    const [systemCutError,setSystemCutError]=useState(false);
    const [roleError,setRoleError] = useState(false);
    const [passwordError,setPasswordError] = useState(false);

    const [showPassword, setShowPassword] = useState(false);

    const companies = useSelector(state=>state.companies.companies);
    const current_user = useSelector(state=>state.auth.user);

    
    const phone_number_error = useSelector(state=>state.auth.phone_number_error);
    const dispatch = useDispatch();

    useEffect(()=>{
      
      setCompany(user? user.company_id : '');
      setFullName(user? user.fullName : '');
      setUserName(user? user.userName : '');
      setPhoneNumber(user? user.phone : '');
      setSystemCut(
        user && user.cut ? user.cut : current_user && current_user.cut ? parseInt(current_user.cut) + 10 : 25
      );
      
      setPlayPercentage(user && user.playPercentage ? user.playPercentage : null);
      setBetAmount(user && user.betAmount ? user.betAmount : null);
       
      setBranch(user? user.branch : '');
      setRole(user? user.role : 'Employee');
      setIsActive(user? user.isActive : true);
      setIsManualTrx(user?user.is_manual_trx:false);
      setIsFixedWinning(user?user.is_fixed_winning:false);

      setUserNameErrorText('');
      

      if(user){
        const val = companies?.find(t=>t.company_id === user.company_id);
        setSelectedCompany(val);
      }
      
    },[user]);

    const handleClick = async () => {

        if(company_id === '' || !company_id){
            setCompanyError(true);
            return;
        }else{
            setCompanyError(false);
        }
        
        if(fullName === '' || !fullName){
            setFullNameError(true);
            return;
        }else{
            setFullNameError(false);
        }

        if(userName === '' || !userName){
            setuserNameError(true);
            return;
        }else{
          if(!user && (userName.includes('.sales' || (userName.includes('.agent'))))){
            setuserNameError(true);
            setUserNameErrorText('user name can not contain (.sales) or (.agent) values.)');
            return;
          }
            setuserNameError(false); 
        }

        setUserNameErrorText('');
        if(phoneNumber === '' || !phoneNumber){
            setPhoneNumError(true);
            return;
        }else{
            setPhoneNumError(false);
        }

        const inputValue = phoneNumber;
        const sanitizedInput = inputValue.replace(/[^0-9]/g, '').slice(0, 10);
        const isValid = /^\d{10}$/.test(sanitizedInput);

        if (!isValid) {
          setPhoneNumError(true);
          return;
        }

        /*if(systemCut === '' || !systemCut || systemCut <= 0){
          setSystemCutError(true);
          return;
        }*/
       
        //for admin less than or less less than or equal to
        if(current_user.role === 'Admin' && current_user.cut !== undefined && systemCut <= current_user.cut-1){
          //alert(current_user.cut)
          setSystemCutError(true);
          return; 
        }
        if((current_user.role !== 'super' && current_user.role !== 'Admin') && current_user.cut !== undefined && systemCut <= current_user.cut){
          //alert(current_user.cut)
          setSystemCutError(true);
          return; 
        }
        setSystemCutError(false);


        if(role === '' || !role){
            setRoleError(true);
            return;
        }else{
            setRoleError(false);
        }

        if(!user && (password === '' || !password)){//if it is user edit password is not mandatory
            setPasswordError(true);
            return;
        }else{
            setPasswordError(false);
        }

        const userData = {
          fullName,
          userName,
          phone: phoneNumber,
          cut:systemCut,
          playPercentage,
          betAmount,
          role,
          branch,
          company_id,
          password,
          isActive,
          is_manual_trx:isManualTrx,
          is_fixed_winning:isFixedWinning
        };

        if(user){
//          console.log(user);
          const update_info = {id:user._id,userData};
          await dispatch(updateUser(update_info));
        }else{
          await dispatch(createUser(userData));
        }
        if(phone_number_error.length > 0){
          setPhoneNumError(true);
          setCompany('');
          setFullName('');
          setUserName('');
          setSystemCut(current_user.cut ? parseInt(current_user.cut) + 10 : 25);
          setPlayPercentage(null);
          setBetAmount(null);
          setuserNameError('');
          setUserNameErrorText('');
          setPhoneNumber('');
          setRole('Employee');
          setBranch('');
          setPassword('');
          setIsManualTrx(false);
          setIsFixedWinning(false);
          setSystemCutError(false);
        }
    };

    const handleCompanyChange = (e)=>{
    
        const val = companies?.find(t=>t.company_id === e.target.value);
        setSelectedCompany(val);
        setCompany(e.target.value)
    }

    const createCompanyList = ()=>{
        var comps = companies.map((company)=>{
              return <MenuItem key={company.company_id} value={company.company_id}>{company.company_name}</MenuItem>
        });
        return comps;
    }

    const handleCheckBox = (e)=>{
    
      setIsActive(e.target.checked)
    }

    return (
      <>
        <Dialog fullWidth open={openDialog} onClose={handleDialogClose}>
          <Container maxWidth="sm">
            <StyledContent>
              <Typography sx={{color: theme.palette.primary.main}} variant="h4" gutterBottom>
                Register User
              </Typography>

              <Divider sx={{ my: 3, color:theme.palette.primary.text }}>
                <Typography  variant="body2" sx={{ color: theme.palette.primary.main }}> 
                  Create User
                </Typography>
              </Divider>

              {/*Start Registration Form*/}
              <Stack spacing={3}>
                <TextField
                  error={companyError}
                  labelId="company"
                  select
                  value={company_id}
                  label="company"
                  defaultValue=""
                  onChange={handleCompanyChange}
                  //onChange={(e) => setCompany(e.target.value)}
                >
                  {createCompanyList()}
                </TextField>
                <TextField
                  error={fullNameError}
                  name="fullName"
                  label="Full Name"
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                />
                <TextField
                  helperText={
                    phone_number_error.length > 0
                      ? phone_number_error
                      : userNameErrorText.length > 0
                      ? userNameErrorText
                      : ''
                  }
                  error={userNameError || phone_number_error.length > 0}
                  name="userName"
                  label="User Name"
                  value={userName}
                  onChange={(e) => setUserName(e.target.value)}
                />

                <TextField
                  helperText={phone_number_error.length > 0 ? phone_number_error : ''}
                  error={phoneNumberError || phone_number_error.length > 0}
                  disabled={phoneEditLock}
                  name="phoneNumber"
                  label="Phone Number (+251)"
                  //onChange={(e) => setPhoneNumber(e.target.value)}
                  onChange={(e) => setPhoneNumber(e.target.value.replace(/[^0-9]/g, '').slice(0, 10))}
                  value={phoneNumber}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton>
                          <Iconify icon="material-symbols:perm-phone-msg" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  helperText={
                    systemCutError ? `cut percentage can not be less than or equal to ${current_user.cut}` : ''
                  }
                  error={systemCutError}
                  name="systemCut"
                  label="Cut (Percentage %)"
                  onChange={(e) =>
                    e.target.value < 0 || e.target.value > 100 ? setSystemCut(25) : setSystemCut(e.target.value)
                  }
                  value={systemCut}
                  type="number"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton>
                          <Iconify icon="fluent:screen-cut-20-regular" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                {(current_user.role === 'super' || current_user.role === 'Admin') && (
                  <Stack direction={'row'} gap={5} flex={1}>
                  {/* Play Percentage Select */}
                  
<FormControl fullWidth>
  <InputLabel id="play-percentage-select-label">Play Percentage (amount 1-30)</InputLabel>
  <Select
    labelId="play-percentage-select-label"
    id="play-percentage-select"
    value={playPercentage}
    label="Play Percentage (amount 1-30)"
    onChange={(e) => setPlayPercentage(e.target.value)}
    startAdornment={(
      <InputAdornment position="start">
        <IconButton>
          <Iconify icon="fluent:screen-cut-20-regular" />
        </IconButton>
      </InputAdornment>
    )}
  >
    {/* Clear option */}
    <MenuItem value="">
      <em>Clear</em>
    </MenuItem>

    {/* Options from 1 to 30 */}
    {Array.from({ length: 30 }, (_, i) => i + 1).map((value) => (
      <MenuItem key={value} value={value}>
        {value}
      </MenuItem>
    ))}
  </Select>
</FormControl>
                
                  {/* Conditional Bet Amount TextField for 'super' user */}
                  {current_user.role === 'super' && (
                    <TextField
                      name="betAmount"
                      label="Minimum Bet Amount"
                      onChange={(e) =>
                        e.target.value < 0
                          ? setBetAmount(20)
                          : setBetAmount(e.target.value)
                      }
                      value={betAmount}
                      type="number"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <IconButton>
                              <Iconify icon="fluent:screen-cut-20-regular" />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      fullWidth
                    />
                  )}
                </Stack>
                )}

                <FormControl fullWidth>
                  <InputLabel error={roleError} id="role-select-label">
                    Role
                  </InputLabel>
                  <Select
                    labelId="role-select-label"
                    id="role-select"
                    value={role}
                    label="Role"
                    onChange={(e) => setRole(e.target.value)}
                  >
                    {roles?.map((r) => {
                      return (
                        <MenuItem key={r} value={r}>
                          {r}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>

                <TextField
                  labelId="branch"
                  select
                  value={branch}
                  label="Branch"
                  defaultValue=""
                  onChange={(e) => setBranch(e.target.value)}
                >
                  {selectedCompany?.branches?.map((branch) => {
                    return (
                      <MenuItem key={branch.id} value={branch.branch_name}>
                        {branch.branch_name}
                      </MenuItem>
                    );
                  })}
                </TextField>

                <TextField
                  error={passwordError}
                  name="password"
                  label="Password"
                  type={showPassword ? 'text' : 'password'}
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                          <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Stack>

              <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
                {current_user.role === 'super' && (
                  <>
                    <FormLabel sx={{color: theme.palette.primary.main}}>Is Active</FormLabel>
                    <Checkbox
                      sx={{color: theme.palette.primary.main, '&.Mui-checked' : {color:theme.palette.primary.main,},}}
                      name="isActive"
                      label="Is Active"
                      value={isActive}
                      checked={isActive}
                      defaultChecked={isActive}
                      onChange={handleCheckBox}
                    />
                  </>
                )}
                <FormLabel sx={{color: theme.palette.primary.main}}>Manual Trx</FormLabel>{' '}
                <Checkbox
                  sx={{color: theme.palette.primary.main, '&.Mui-checked' : {color:theme.palette.primary.main,},}}
                  name="manualTrx"
                  label="Manual Trx"
                  value={isManualTrx}
                  checked={isManualTrx}
                  defaultChecked={isManualTrx}
                  onChange={(e) => setIsManualTrx(e.target.checked)}
                />
                <FormLabel sx={{color: theme.palette.primary.main}}>Is Fixed Winning</FormLabel>{' '}
                <Checkbox
                  sx={{color: theme.palette.primary.main, '&.Mui-checked' : {color:theme.palette.primary.main,},}}
                  name="isFixedWinning"
                  label="Is Fixed Winning"
                  value={isFixedWinning}
                  checked={isFixedWinning}
                  defaultChecked={isFixedWinning}
                  onChange={(e) => setIsFixedWinning(e.target.checked)}
                />
              </Stack>

              <LoadingButton sx={{backgroundColor: theme.palette.primary.main,color:theme.palette.primary.text, color:'white', '&:hover' : {backgroundColor: theme.palette.primary.main, color: 'white'},}} fullWidth size="large" type="submit" variant="contained" onClick={handleClick}>
                Save
              </LoadingButton>
              {/*end Registration Form*/}
            </StyledContent>
          </Container>
        </Dialog>
      </>
    );
}
