import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { passwordChange } from 'src/redux/slices/userSlice';

import { create_notification } from 'src/redux/slices/appStateSlice';

export default function NotificationModal({open,handleNotificationDialogClose,company}) {

    const dispatch = useDispatch();

    const [_companyId,setCompanyId] = useState(company);
    const [_title,setTitle] = useState('');
    const [_message,setMessage] = useState('');

    const [companyError,setCompanyError] = useState(false);
    const [titleError,setTitleError] = useState(false);
    const [msgError,setMsgError] = useState(false);

    const save_message = useSelector(state=>state.pwd_change_status);

    const [status,setStatus] = useState('');

   const handleSendMessage = () => {

     if (!_companyId || _companyId.length == 0) {
        setCompanyError(true);
       return;
     } else {
        setCompanyError(false);
     }
     if (!_title || _title.length == 0) {
        setTitleError(true);
       return;
     } else {
        setTitleError(false);
     }
     if (!_message || _message.length == 0) {
        setMsgError(true);
       return;
     } else {
        setMsgError(false);
     }

     //handle password change
     const create_notitication_obj = {
        title : _title,
        msg : _message,
        company_id : _companyId
    };
     dispatch(create_notification(create_notitication_obj));
     
    
     setTitle('');
     setMessage('');
   };

    
  return (
    <div>
      <Dialog open={open} onClose={handleNotificationDialogClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Send Message</DialogTitle>
        <span style={{textAlign :'center'}}>{save_message || status}</span>
        <DialogContent>
          <TextField
          error={companyError}
            autoFocus
            margin="dense"
            id="company_id"
            label="Company Id"
            type="text"
            fullWidth
            value={company}
            onChange={(e)=>setCompanyId(e.target.value)}
            disabled
          />
          <TextField
          error={titleError}
            margin="dense"
            id="title"
            label="Title"
            type="text"
            fullWidth
            value={_title}
            onChange={(e)=>setTitle(e.target.value)}
          />
          <TextField
          error={msgError}
            margin="dense"
            id="message"
            label="Message"
            type="text"
            fullWidth
            value={_message}
            onChange={(e)=>setMessage(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleNotificationDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSendMessage} color="primary">
            Send
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}