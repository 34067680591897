import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { useSelector,useDispatch } from 'react-redux';
import { getAttendance } from 'src/redux/slices/transactionSlice';
import { useTheme } from '@mui/material/styles';

const StyledStatusTableCell = styled(TableCell)(({ theme }) => ({

  [`&.${tableCellClasses.head}`]: {
    backgroundColor: 'white',
    color: '#04297a',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontWeight:'bold'
  },
  // Additional styles for active and inactive cells
  '&.active': {
    color: 'green', // Change text color for active cells
  },
  '&.inactive': {
    color: 'red', // Change text color for inactive cells
  },
  '& .circle': {
    display: 'inline-block',
    width: 10,
    height: 10,
    borderRadius: '50%',
    marginRight: 5,
    ackgroundColor: 'green', // Adjust spacing if needed
  },
  '& .active .circle': {
    backgroundColor: 'green', // Change circle color to green for active cells
  },
  '& .inactive .circle': {
    backgroundColor: theme.palette.primary.main, // Change circle color to red for inactive cells
  },
}));


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: 'white',
    color: theme.palette.primary.main,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
  color:'#000'
}));

const StyledTableRow = styled(TableRow)(({ theme, type }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: type === 'attendance' ? theme.palette.primary.main : theme.palette.primary.main,
    color: theme.palette.primary.text,
  },
  '&:nth-of-type(even)': {
    color: theme.palette.primary.main, // Set text color for even rows to primary.main
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const Circle = styled('span')({
  display: 'inline-block',
  width: 20,
  height: 20,
  borderRadius: '50%',
  marginRight: 5, // Adjust spacing if needed
});

const currency_formatter = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
});


import { Refresh } from '@mui/icons-material';

export default function CustomBinTable({type,column_data}) {

  const theme = useTheme()
  const dispatch = useDispatch();

  const dashboard_data_per_company = useSelector((state) => state.transactionList.dashboard_data_per_company);
  
  const attendance_status = useSelector((state)=>state.transactionList.attendanceData);
  
  React.useEffect(()=>{
    //console.log(dashboard_data_per_company);
  },[]);

  const loadAttendance = async () => {
    await dispatch(getAttendance(new Date()))
  }

  const setColumnHeader = (data) => {
    return data.map((value)=>{
      if(type === 'attendance' && value === 'SN'){
        return <StyledTableCell align='center' onClick={loadAttendance} style={{cursor:'pointer'}}><Refresh/></StyledTableCell>
      }
      return <StyledTableCell align="center">{value}</StyledTableCell>
    })
  }

  const isInactive = (trxDate) => {
    const twentyMinutesAgo = new Date(Date.now() - 20 * 60000); // 20 minutes ago
    const trxDateTime = new Date(trxDate);
    return trxDateTime < twentyMinutesAgo;
  };

  const CustomTableCell = ({ row }) => {
    const isActive = !isInactive(row.trx_date); // Assuming isInactive is a function returning true for inactive dates
  
    return (
    <StyledTableCell align="center">
    <Circle style={{ backgroundColor: isActive ? 'green' : theme.palette.primary.main }} />
    </StyledTableCell>
    );
  };

  const getTimeDifference = (trxDate) => {


    const diffInMilliseconds = Date.now() - new Date(trxDate);

    const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));
  
    //console.log(`${Date.now()} - ${new Date(trxDate)} - ${trxDate} - ${diffInMilliseconds} - ${diffInMinutes}`)

    if (diffInMinutes < 60) {
      return `${diffInMinutes} mins ago`;
    } else if (diffInMinutes < 24 * 60) {
      const diffInHours = Math.floor(diffInMinutes / 60);
      return `${diffInHours} hour${diffInHours > 1 ? 's' : ''} ago`;
    } else {
      return 'long time ago';
    }
  };

  const [total_winnings,setTotalWinnings] = React.useState(0);
  const [total_cut,setTotalCut] = React.useState(0);
  const [total_trx,setTotalTrx] = React.useState(0);

  React.useEffect(()=>{
    if(dashboard_data_per_company){
      let winningsSum = 0;
      let cutSum = 0;
      let trxSum = 0;

    dashboard_data_per_company.forEach(companyData => {
      winningsSum += companyData.total_winning || 0;
      cutSum += companyData.total_cut || 0;
      trxSum += companyData.total_trx || 0;
    });

    // Update state values
    setTotalWinnings(winningsSum);
    setTotalCut(cutSum);
    setTotalTrx(trxSum);
    }
  },[dashboard_data_per_company])


  
  return (
    <>
      {type === 'sales' && (
        <span style={{ color: theme.palette.primary.main, fontSize: '14px', textAlign: 'right' }}>
          {`Total winnings: ${total_winnings.toLocaleString()} | Total cut: ${total_cut.toLocaleString()} | Total trx: ${total_trx.toLocaleString()}`}
        </span>
      )}
      <TableContainer component={Paper}>
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>{setColumnHeader(column_data)}</TableRow>
          </TableHead>
          <TableBody>
            {type === 'sales' &&
              dashboard_data_per_company?.map((row, index) => (
                <StyledTableRow key={row.name} type={type}>
                  <StyledTableCell align="center">{++index}</StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {row.company_name}
                  </StyledTableCell>
                  <StyledTableCell align="center">{currency_formatter.format(row.total_winning)}</StyledTableCell>
                  <StyledTableCell align="center">{currency_formatter.format(row.total_cut)}</StyledTableCell>
                  <StyledTableCell align="center">{currency_formatter.format(row.total_trx)}</StyledTableCell>
                </StyledTableRow>
              ))}

            {type === 'attendance' &&
              attendance_status?.map((row, index) => (
                <StyledTableRow key={row.name} type={type}>
                  <StyledTableCell align="center">{++index}</StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {row.company_name}
                  </StyledTableCell>
                  <StyledTableCell align="center">{getTimeDifference(row.trx_date)}</StyledTableCell>
                  <StyledTableCell align="center">{row.employee}</StyledTableCell>
                  {/* /*<StyledTableCell align="center">
                {isInactive(row.trx_date) ? 'In Active' : 'Active'}
              </StyledTableCell>*/}

                  <CustomTableCell row={row} />
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

