import axios from 'axios'


//const API_URL = 'http://localhost:5000/api'

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });

const getToken = ()=>{
    const token = localStorage.getItem('bingo_access_token');
    const config = {
        headers:{
            Authorization: `Bearer ${token}`
        }
    };
    return config;
}

const create_notification = async (notificationObj) => {
    try {
        const data = await axiosInstance.post('/notification/createNotification',notificationObj,getToken());
        return data;
    } catch (error) {
        checkStatusAndRefreshPage(error);
        console.log(error);
        throw error.response.data;
    }
}

const get_notification = async (company_id) => {
    try {
        
        const data = await axiosInstance.get(`/notification/${company_id}`,getToken());
        return data;
    } catch (error) {
        checkStatusAndRefreshPage(error);
        console.log(error);
        throw error.response.data;
    }
}

const set_notification_seen = async (id) => {
    try {
        const obj = {_id:id};
        const data = await axiosInstance.post('/notification/setSeen',obj,getToken());
        return data;
    } catch (error) {
        checkStatusAndRefreshPage(error);
        console.log(error);
        throw error.response.data;
    }
}
const notificationsService = {
    get_notification,
    create_notification,
    set_notification_seen
};

export default notificationsService;