import React from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";

const RoleCheck = ({ isAdmin ,component}) => {
    const location = useLocation();
    //console.log('is adming from role check' + isAdmin);
    return isAdmin ? (component) :
        (<Navigate to="/404" state={{ from: location }} replace />);
}
export default RoleCheck;
