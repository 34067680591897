import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { passwordChange } from 'src/redux/slices/userSlice';

export default function PasswordChangeModal({open,handleClose}) {

    const dispatch = useDispatch();

    const [currentPwd,setCurrentPwd] = useState('');
    const [newPwd,setNewPwd] = useState('');
    const [confirmPwd,setConfirmPwd] = useState('');

    const [currrentPwdError,setCurrentPwdError] = useState(false);
    const [newPwdError,setNewPwdError] = useState(false);
    const [confirmPwdError,setConfirmPwdError] = useState(false);

    const save_message = useSelector(state=>state.auth.pwd_change_status);

    const [status,setStatus] = useState('');

   const handleChangePassword = () => {

     if (!currentPwd || currentPwd.length == 0) {
       setCurrentPwdError(true);
       return;
     } else {
       setCurrentPwdError(false);
     }
     if (!newPwd || newPwd.length == 0) {
       setNewPwdError(true);
       return;
     } else {
       setNewPwdError(false);
     }
     if (!confirmPwd || confirmPwd.length == 0) {
       setConfirmPwdError(true);
       return;
     } else {
       setConfirmPwdError(false);
     }

     if (newPwd !== confirmPwd) {
       setStatus('Password Mismatch!');
       setNewPwdError(true);
       setConfirmPwdError(true);
       return;
     }

     //handle password change
     const pwd_change_obj = {
        current_pwd : currentPwd,
        new_pwd : newPwd
    };
     dispatch(passwordChange(pwd_change_obj));
     
     setStatus('');
     setCurrentPwd('');
     setNewPwd('');
     setConfirmPwd('');
     //handleClose();
   };

    
  return (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Change Password</DialogTitle>
        <span style={{textAlign :'center'}}>{save_message || status}</span>
        <DialogContent>
          <TextField
          error={currrentPwdError}
            autoFocus
            margin="dense"
            id="current-password"
            label="Current Password"
            type="password"
            fullWidth
            value={currentPwd}
            onChange={(e)=>setCurrentPwd(e.target.value)}
          />
          <TextField
          error={newPwdError}
            margin="dense"
            id="new-password"
            label="New Password"
            type="password"
            fullWidth
            value={newPwd}
            onChange={(e)=>setNewPwd(e.target.value)}
          />
          <TextField
          error={confirmPwdError}
            margin="dense"
            id="confirm-new-password"
            label="Confirm New Password"
            type="password"
            fullWidth
            value={confirmPwd}
            onChange={(e)=>setConfirmPwd(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleChangePassword} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}