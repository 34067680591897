
import React, { useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';



import '../sass/bingoboard.scss';

import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { check_winning, disqualify_player, reset_winning_info } from 'src/redux/slices/boardSlice';
import { create_winning_transaction,update_transaction ,reset_gamePlay_data, set_game_is_over, set_winner_lists, void_transaction, set_dynamic_pattern_one } from 'src/redux/slices/gamePlaySlice';

import ResultDialogBox from './ResultDialogBox';
import CustomizedSnackbars from './SnackBar';

import { winnerVoice,looserVoice,tigLosserVoice,tigWinnerVoice,oroLosserVoice,oroWinnerVoice,wolLosserVoice,wolWinnerVoice } from '../chimes';
import { Stack } from '@mui/material';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const preset_patterns = [
  {pattern_id:null,pattern_name:'Default',is_active:true},
  {pattern_id:140,pattern_name:'Any Vertical',is_active:false},
  {pattern_id:141,pattern_name:'Any Horizontal',is_active:false},
  {pattern_id:142,pattern_name:'Any 2 Vertical',is_active:false},
  {pattern_id:143,pattern_name:'Any 2 Horizontal',is_active:false},
  {pattern_id:144,pattern_name:'Any Diagonal',is_active:false},
  {pattern_id:145,pattern_name:'Any 1 Corner Square',is_active:false},
  {pattern_id:146,pattern_name:'Any 2 Corner Square',is_active:false},
  {pattern_id:147,pattern_name:'Any 3 Corner Square',is_active:false},
  {pattern_id:148,pattern_name:'All 4 Corner Square',is_active:false},
  {pattern_id:149,pattern_name:'Any 2 Lines',is_active:true},
  {pattern_id:150,pattern_name:'Any 3 Lines',is_active:true},
  {pattern_id:151,pattern_name:'All 4 Corner Square (Single)',is_active:false},
  {pattern_id:152,pattern_name:'Any Line',is_active:false},
  {pattern_id:153,pattern_name:'4 Single Middle',is_active:true},
]

const preset_pattern_indicators = [
  { id: 140, pattern: [10, 11, 12, 13, 14] },
  { id: 142, pattern: [5, 6, 7, 8, 9, 15, 16, 17, 18, 19] },
  { id: 141, pattern: [2, 7, 12, 17, 22] },
  { id: 143, pattern: [1, 6, 11, 16, 21, 3, 8, 13, 18, 23] },

  { id: 144, pattern: [0, 6, 12, 18, 24, 4, 8, 12, 16, 20] },
  { id: 145, pattern: [3, 8, 4, 9] },
  { id: 146, pattern: [3, 8, 4, 9, 15, 16, 20, 21] },
  { id: 147, pattern: [3, 8, 4, 9, 15, 16, 20, 21, 0, 1, 5, 6] },
  { id: 148, pattern: [3, 8, 4, 9, 15, 16, 20, 21, 0, 1, 5, 6, 18, 23, 24, 19] },
  { id: 149, pattern: [0, 6, 12, 18, 24, 4, 8, 12, 16, 20, 10, 11, 12, 13, 14, 2, 7, 12, 17, 22] },
  { id: 150, pattern: [0, 1, 2, 3, 4, 6, 12, 18, 24, 9, 14, 19] },
  { id: 151, pattern: [0, 4, 20, 24] },
  { id: 152, pattern: [0, 6, 12, 18, 24, 4, 8, 12, 16, 20, 10, 11, 12, 13, 14, 2, 7, 12, 17, 22] },
  { id: 153, pattern: [6,8,16,18] },
];

export default function CartelaNumberField({ check_result_field_active, call_list,use_pattern_selected,reset_current_voice}) {

  const dispatch = useDispatch();

  const [board_number, setBoardNumber] = useState(-1);
  const [open, setOpen] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState({ messageTitle: '', messageBody: '', isWinner: false });
  const [playerNotOnList, setPlayerNotOnList] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');

  const winning_info = useSelector((state) => state.boards.winning_info);
  const selected_boards = useSelector((state) => state.boards.selected_board_ids);
  const disqualified_boards = useSelector((state) => state.boards.disqualified_board_ids);
  const userData = useSelector((state) => state.auth.user);
  const transaction_state = useSelector((state) => state.transactionState.transaction_info);

  const bingo_play_language = useSelector((state)=> state.companies.bingo_play_language);

  //get game type and bet amount from global state
  const game_type = useSelector((state) => state.transactionState.game_type);
  const bet_amount = useSelector((state) => state.transactionState.bet_amount);
  const fixed_amount = useSelector((state) => state.transactionState.fixed_winning);
  const game_is_over = useSelector((state)=>state.transactionState.game_is_over);
  const manual_trx_save = useSelector((state)=> state.auth.user?.is_manual_trx);

  //get selected pattern if there is any
  const selected_pattern = useSelector((state) => state.transactionList.selectedPattern);
  const refund_pattern = useSelector((state) => state.transactionList.refundPattern);

  //check if voice is enabled
  const voice_status_enabled = useSelector((state=>state.companies.enable_voice_status));

  //dynamic patterns
  const dynamic_pattern_one = useSelector((state)=>state.transactionState.dynamic_pattern_one);
  const dynamic_pattern_two = useSelector((state)=>state.transactionState.dynamic_pattern_two);

  //get current_trx_id
  const current_trx_id = useSelector((state)=>state.transactionState.current_trx_id);
  const winner_lists = useSelector((state)=>state.transactionState.winner_lists);
  //side effect to save transaction or show error dialog

  //this state will decided if the selected patterns are checked together or a single pattern is enough for winning
  const isOrOperator = useSelector((state)=>state.transactionState._isOR_Operator);

  useEffect(() => {
    if (board_number != -1) {
      if (winning_info?.is_winner) {
        setDialogMessage({
          messageTitle: '',
          messageBody: '',
        });
       
        try {
          if(!userData?.is_manual_trx /*&& voice_status_enabled*/){
            if(bingo_play_language == 1){//play tigrigna
              let audio = new Audio(tigWinnerVoice);
              audio.play();
            }
            else if(bingo_play_language == 2){//play oromigna
              let audio = new Audio(oroWinnerVoice);
              audio.play();
            }else if(bingo_play_language == 3){
              let audio = new Audio(wolWinnerVoice);
              audio.play();
            }
            else{//default amharic voice
              let audio = new Audio(winnerVoice);
              audio.play();
            }
          }
        } catch (error) {
          console.log('user data is null when checking user information for sound play!');
        }
        setOpen(true);
        //save_winning_transaction();

        //if player is a winner set it to winner list so that, if there happens to happen 3 or more players the trx will be voided.
        dispatch(set_winner_lists(board_number));
      }

      if (winning_info?.is_losser) {
        //play losing voice
        try {
          if(!userData?.is_manual_trx /*&& voice_status_enabled*/){
            if(bingo_play_language == 1){//play tigrigna
              let audio = new Audio(tigLosserVoice);
              audio.play();
            }
            else if(bingo_play_language == 2){//play oromigna
              let audio = new Audio(oroLosserVoice);
              audio.play();
            }
            else if(bingo_play_language == 3){
              //wolLosserVoice
              let audio = new Audio(wolLosserVoice);
              audio.play();
            }
            else{//default amharic voice
              let audio = new Audio(looserVoice);
              audio.play();
            }
          }
        } catch (error) {
          console.log('user data is null when checking user information for sound play!');
        }
        losing_dialog_setup();
      }
    }
  }, [winning_info]);

  //side effect to reset playing board based on saving result
  useEffect(() => {
  }, [transaction_state]);

  const save_winning_transaction = async () => {
    if(game_is_over)
      {
        setDialogMessage({
          messageTitle: 'Game Over!',
          messageBody: 'Please Reset or Restart to Start a brand new game!',
        });
        //setPlayerNotOnList(true);
        setOpen(true);
        return;
      }

      var winning_player_isDiqalified = disqualified_boards.find(t=>t === board_number);
      if(winning_player_isDiqalified){
        setDialogMessage({
          messageTitle: 'Board Diqalified!',
          messageBody: 'Winning Player is disqualified!',
        });
        //setPlayerNotOnList(true);
        setOpen(true);
        return;
      }

      let _lat = 0;
      let _lon = 0;

      try {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            _lat = position.coords.latitude;
            _lon = position.coords.longitude;
          },
          (error) => {
            console.error("Error getting location:", error);
          }
        );
      } catch (error) {
        console.error("Error getting location:", error);
      }

    //if fixed
    const game_data = {
      game_type,
      bet_amount,
      winning_board_id: board_number,
      selected_boards: selected_boards.concat(disqualified_boards),
      call_list,
      cashier: userData.userName,
      branch: userData.branch,
      company_id: userData.company_id,
      fixed_amount,
      lat:_lat,
      lon:_lon
    };
    await dispatch(create_winning_transaction(game_data));
    setOpen(true);
    setDialogMessage({
      messageTitle: '',//'Player Won!',
      messageBody: '',//`Player with board number [${board_number}] has won the game. Saving Transactions ...`,
      isWinner: true,
    });

    const _gme_data = {
      call_list: call_list,
      selected_boards: selected_boards.concat(disqualified_boards),
      'winning board': board_number,
    };

    localStorage.setItem('last_play_data',JSON.stringify(_gme_data));
    //setPlayerNotOnList(false);
    //setOpen(false);
  };

  const losing_dialog_setup = () => {
    setDialogMessage({
      messageTitle: '',//'Player Lost!',
      messageBody: '',//`Player with board number [${board_number}] has lost. Do you want to Disqualify this player?!`,
      isWinner: false,
    });
    setOpen(true);
    //setPlayerNotOnList(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSnackClose = () => {
    setSnackOpen(false);
  };

  const handleDisqualify = () => {
    dispatch(disqualify_player(board_number));
    setOpen(false);
  };

  //game over clicked
  const handleResetForm = () => {
    //dispatch(reset_winning_info());
    //dispatch(reset_gamePlay_data());
    
    //check if there are 3 or more winners and void the trx.
    //dispatch to update the trx
    reset_current_voice();
    if(winner_lists?._count >= 3 && !game_is_over){
      dispatch(void_transaction(current_trx_id));
    }

    dispatch(set_game_is_over(true));
    //update the saved trxn with new info
    try {
      if(current_trx_id){
        const update_object = {trx_id:current_trx_id,winning_board_ids:winner_lists.winning_boards,call_list:call_list.length}
        dispatch(update_transaction(update_object));
      }
    } catch (error) {
      
    }
    
    setOpen(false);


    

  };

  let call_list_stripped = call_list.map((num) => num.number);

  const _handleKeyDown = async (e) => {

    if (e.key === 'Enter' && board_number > 0) {
      setPlayerNotOnList(false);
      if(game_is_over)
      {
        setDialogMessage({
          messageTitle: 'Game Over!',
          messageBody: 'Please Reset or Restart to Start a brand new game!',
        });
        //setPlayerNotOnList(true);

        const player_active = selected_boards.findIndex((t) => t == board_number) > -1; //if entered board not in list of playing boards or is disqualified return
        if (!player_active) {
          setDialogMessage({
            messageTitle: 'player Disqualified!',
            messageBody: 'player board not on list (not selected or disqualified)!',
          });
          setPlayerNotOnList(true);
          setOpen(true);
          return;
        }
        
        await dispatch(check_winning({ id: board_number, call_list: call_list_stripped,pattern:selected_pattern,patternId1:dynamic_pattern_one,patternId2:dynamic_pattern_two,refundPattern:refund_pattern,isOrOp:isOrOperator }));
        setOpen(true);
        return;
      }
      const player_active = selected_boards.findIndex((t) => t == board_number) > -1; //if entered board not in list of playing boards or is disqualified return
      if (!player_active) {
        setDialogMessage({
          messageTitle: 'player Disqualified!',
          //messageBody: 'player board not on list (not selected or disqualified)!',
          messageBody: 'ካርቴላው አልተመረጠም ወይም ታስሯል',
        });
        setPlayerNotOnList(true);
        setOpen(true);
        return;
      }

      if (!call_list_stripped || call_list_stripped.length == 0) call_list_stripped = [0];
      await dispatch(check_winning({ id: board_number, call_list: call_list_stripped,pattern:selected_pattern,patternId1:dynamic_pattern_one,patternId2:dynamic_pattern_two,refundPattern:refund_pattern,isOrOp:isOrOperator  }));
    }
  };

  const [pattern1,setPattern1] = useState(null);//type 1
  //const dispatch = useDispatch();
  const handlePatternOneChange = (e) => {

    const newValue = e.target.value;
    /*if(!newValue){
      handlePatternClear();
      return;
    }*/
    
    //const selectedPattern = preset_pattern_indicators.find(item => item.id === parseInt(newValue))?.pattern;
    setPattern1(newValue);
    //setPattern1Draw([]);
    /*if (selectedPattern) {
      setPattern1Draw(prevPatternDraw => [...prevPatternDraw, ...selectedPattern]);
    }*/
    
    dispatch(set_dynamic_pattern_one(e.target.value));

  };


  return (
    <>
      <AlertDialogSlide
        open={open}
        playerNotOnList={playerNotOnList}
        dialogMessage={dialogMessage}
        handleClose={handleClose}
        disqualify={handleDisqualify}
        reset={handleResetForm}
        winning_info={winning_info}
        manual_trx_save={manual_trx_save}
        handle_save_trxn = {save_winning_transaction}
        board_number = {board_number}
      />
      
      <CustomizedSnackbars snackOpen={snackOpen} snackMessage={snackMessage} handleSnackClose={handleSnackClose} is_saved={transaction_state.is_saved} is_failed={transaction_state.is_failed} is_loading={transaction_state.is_loading} />
      
      <Stack direction={'row'} gap={'2px'} width={'100%'}>
      <TextField
        id="outlined-number"
        placeholder="ካርቴላ ቁጥር"
        type="number"
        fullWidth
        style={{ backgroundColor: '#fff', borderRadius: '5px' }}
        disabled={check_result_field_active}
        onKeyDown={_handleKeyDown}
        onChange={(e) => setBoardNumber(e.target.value)}
        InputLabelProps={{
          shrink: true,
        }}
    />
      {/* <TextField
                sx={{
                  label: { color: 'black', '&:focus' : {color: 'white'} },
                  '& .MuiInputLabel-root.Mui-focused': {
                    color: 'gray', 
                  },
                  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutlFine': {
                    borderColor: 'gray' 
                  },
                  '& .MuiOutlinedInput-input': {
                    color: 'black'  
                  },
                  '& .MuiSelect-icon': {
                    color: 'gray'  
                  }
                }}
                labelId="Primary_Pattern"
                select
                value={pattern1}
                label="Pattern"
                onChange={handlePatternOneChange}
                fullWidth
              >
                {preset_patterns?.map((pattern) => {
                  if (pattern.is_active) {
                    return (
                      <MenuItem key={pattern.pattern_id} value={pattern.pattern_id}>
                        {pattern.pattern_name}
                      </MenuItem>
                    );
                  }
                  return null; // Ensure a return value in all branches
                })}

              </TextField> */}
      </Stack>
      

    </>
  );
}

function AlertDialogSlide({ open, playerNotOnList, dialogMessage, handleClose, disqualify, reset, winning_info,manual_trx_save,handle_save_trxn,board_number }) {
  
  const manual_save_btns = () =>{
    return (
      <>
        <Button onClick={disqualify}>Disqualify</Button>
        <Button onClick={handleClose}>Pass</Button>
        {/* <Button onClick={handle_save_trxn}>Save</Button>  */}
        <Button onClick={reset}>Done</Button>
      </>
    )
  }
  const auto_save_btns = () => {
    return (<>
      {

        !playerNotOnList ? (
          <>
            <Button onClick={disqualify}>ካርቴላው ይታሰር</Button>
            <Button onClick={handleClose}>ካርቴላው ይቀጥል</Button>
            <Button onClick={reset}>ጨዋታው ተጠናቋል</Button>
           
          </>
        ):
        (
          <>
          <Button onClick={handleClose}>Close</Button>
          <Button onClick={reset}>Game Over</Button>
          </>
        )
      }
      {/* {(!dialogMessage?.isWinner && <Button onClick={handleClose}>{playerNotOnList ? 'OK' : 'Pass'}</Button>)} */}
    </>);
  }

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        // fullScreen
      >
        <DialogTitle
          style={{ color: dialogMessage?.isWinner ? 'blue' : 'red', textAlign: 'center', fontWeight: 'bolder' }}
        >
          {dialogMessage?.messageTitle}
        </DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description" style={{ fontWeight: 'bold', textAlign: 'center' }}>
            {dialogMessage?.messageBody}
          </DialogContentText>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {!playerNotOnList && <ResultDialogBox winning_info={winning_info} manual_trx_save={manual_trx_save} />}
            {!playerNotOnList && !manual_trx_save && (
              <div>
                {/* <h1 style={{ fontSize: '48px' }}>
                  Cartela Number: <b style={{ color: 'blue' }}>{board_number}</b>
                </h1> */}
                {/* <h1 style={{ fontSize: '48px', color: winning_info?.is_winner ? 'blue' : 'red' }}>
                  {winning_info?.is_winner ? 'Winner' : 'Looser'}
                  <b style={{ marginLeft: '32px', fontSize: '74px' }}>
                    <span
                      dangerouslySetInnerHTML={{ __html: `${winning_info?.is_winner ? '&#10004;' : '&#10005;'}` }}
                    />
                  </b>
                </h1> */}
                {/* <h1
                  style={{
                    fontSize: '48px',
                    color: (winning_info?.is_winner && ! winning_info?.is_refund) ? 'blue' : winning_info?.is_refund ? 'green' : 'red',
                  }}
                >
                  {winning_info?.is_winner && !winning_info?.is_refund ? (
                    <>
                      Winner
                      <b style={{ marginLeft: '32px', fontSize: '74px' }}>
                        <span dangerouslySetInnerHTML={{ __html: '&#10004;' }} />
                      </b>
                    </>
                  ) : winning_info?.is_refund ? (
                    <>
                      Refund
                      <b style={{ marginLeft: '32px', fontSize: '74px' }}>
                        <span dangerouslySetInnerHTML={{ __html: '&#128176;' }} />
                      </b>
                    </>
                  ) : (
                    <>
                      Looser
                      <b style={{ marginLeft: '32px', fontSize: '74px' }}>
                        <span dangerouslySetInnerHTML={{ __html: '&#10005;' }} />
                      </b>
                    </>
                  )}
                </h1> */}
              </div>
            )}
          </div>
        </DialogContent>

        <DialogActions>
          {auto_save_btns()}
          {/* {manual_trx_save ? manual_save_btns() : auto_save_btns()} */}
        </DialogActions>
      </Dialog>
    </div>
  );
}




